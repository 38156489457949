/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

const imagesPrefix =
  "http://localhost:3001/client/images";

export default [
  {
    image: `${imagesPrefix}/print.png`,
    route: "/pages/landing-pages/about-us",
  },
  {
    image: `${imagesPrefix}/banner.jpg`,
    route: "/pages/landing-pages/contact-us",
  },
  {
    image: `${imagesPrefix}/visitcard.png`,
    route: "/pages/authentication/sign-in",
  },
];
