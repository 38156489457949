// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";


// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

// Dashboard components
import {useEffect, useState} from "react";
import MDTypography from "../../components/MDTypography";
import SweetAlert2 from "react-sweetalert2";
import MDCardCustom from "components/MDCardCustom";
import sellerService from "services/seller-service";
import { Pagination } from "@mui/material";
import { defaultLogo } from "services/helperFunctions";

function Sellers() {
    const [swalProps, setSwalProps] = useState({});
    const [sellers, setSellers] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const [loading, setLoading] = useState(true);
    const [sellerBoxes, setSellerBoxes] = useState([]);
    const [notification, setNotification] = useState({ color: null, title: '' });


    const fetchSellers = async (page) => {
      
        setLoading(true);
        try {
          const response = await sellerService.allSellers(page);
          if (response.data.status == 'error') {
            setNotification({ color: 'error', title: response.data.message });
          } else {
            console.log(response.data.data);
            
            setSellers(response.data.data);
            setTotalPages(response.data.last_page);
          }
        } catch (error) {
          setNotification({ color: 'error', title: 'Failed to fetch sellers.' });
        } finally {
          setLoading(false);
        }
     
    };

  

  useEffect(() => {

    setSellerBoxes(sellers.map(seller => (
      <Grid item xs={12} mb={5} key={seller.id}>
          <MDCardCustom
              title={seller.name}
              address={seller.address}
              route={`/sellers/${seller.id}/profile`}
              badge={seller.isOpen}
              description={seller.summary}
              image={seller.logo ?? defaultLogo} // Update image if different for each seller
              buttonTitle='مشاهده'
              rating={seller.average_rate ?? 'تازه وارد'}
          />
      </Grid>
  )));

}, [sellers]);

    const handlePageChange = (event, value) => {
  
        setCurrentPage(value); // Update the current page
        fetchSellers(value);
      };

    useEffect(() => {
        fetchSellers(currentPage);

    }, []);

    return (
        <DashboardLayout>
        <DashboardNavbar />
        <MDBox pt={2} pb={3}>
          <Grid container  direction="row" spacing={6}>
              <SweetAlert2 {...swalProps} />

            <Grid item xs={12}>
                <MDBox
                  mx={2}
                  mt={-3}
                  py={3}
                  px={2}
                  variant="gradient"
                  bgColor="info"
                  borderRadius="lg"
                  coloredShadow="info"
                >
                  <MDTypography variant="h6" color="white">
                    دفاتر فنی
                  </MDTypography>
                </MDBox>
            </Grid>
            <Grid item xs={12}>
             {sellers ? sellerBoxes : 'دفتر فنی یافت نشد'}
            </Grid>

          </Grid>

          <Pagination
            count={totalPages}
            page={currentPage}
            onChange={handlePageChange}
            variant="outlined"
            color="primary"
        />
        </MDBox>
      </DashboardLayout>
    );
}

export default Sellers;
