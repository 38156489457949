export const translateWord = (word) => {
  switch (word){
      case 'user-profile' :
          return 'پروفایل'
      case 'dashboard' :
          return 'داشبورد'
   
      case 'home' :
          return 'خانه'
 
      case 'orders' :
          return 'سفارشات'
    
      case 'sellers' :
          return 'دفاتر فنی'
    
      case 'create-order' :
          return 'ایجاد سفارش'
     
      case 'Packages' :
          return 'پکیج ها'
    
      case 'step2' :
          return 'مرحله دو'
    
      case 'step3' :
          return 'مرحله سه'
    
  }
};

// export const uploadUrl = 'http://localhost:8000/upload/files/';
export const uploadUrl = 'https://app.easychapp.com/upload/files/';
export const SupportTelegramLink = 'https://t.me/easychapp_support';
// export const defaultLogo = 'http://localhost:3001/images/printer.jpg';
export const defaultLogo = 'https://app.easychapp.com/images/printer.jpg';
// export const staticImagesPrefix ="http://localhost:3001/images";
export const staticImagesPrefix ="https://app.easychapp.com/images";
export const numberFormat = (number) => {
    const options = { style: 'decimal', minimumFractionDigits: 0, maximumFractionDigits: 0 };

    const formattedNumber = number.toLocaleString('en-US', options);
    return formattedNumber;
};

export const persianNumber = (number) => {
    const persianDigits = ['۰', '۱', '۲', '۳', '۴', '۵', '۶', '۷', '۸', '۹'];
    const englishDigits = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'];

    let englishNumber = '';
    
    for (let char of number) {
        const index = persianDigits.indexOf(char);
        if (index !== -1) {
            englishNumber += englishDigits[index];
        } else {
            englishNumber += char; // Keep non-digit characters unchanged
        }
    }

    return englishNumber;
};
