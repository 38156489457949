/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

/**
  All of the routes for the Material Dashboard 2 React are added here,
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the Sidenav  .

  For adding a new route you can follow the existing routes in the routes array.
  1. The `type` key with the `collapse` value is used for a route.
  2. The `type` key with the `title` value is used for a title inside the Sidenav.
  3. The `type` key with the `divider` value is used for a divider between Sidenav items.
  4. The `name` key is used for the name of the route on the Sidenav.
  5. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
  6. The `icon` key is used for the icon of the route on the Sidenav, you have to add a node.
  7. The `collapse` key is used for making a collapsible item on the Sidenav that has other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  8. The `route` key is used to store the route location which is used for the react router.
  9. The `href` key is used to store the external links location.
  10. The `title` key is only for the item with the type of `title` and its used for the title text on the Sidenav.
  10. The `component` key is used to store the component of its route.
*/

// Material Dashboard 2 React layouts
import Dashboard from "layouts/dashboard";
import Orders from "layouts/orders";
import Order from "layouts/order";
import SignIn from "layouts/authentication/sign-in";

import UserProfile from "layouts/user-profile";

import Login from "auth/login";
import Sellers from "layouts/sellers";
import LocalMallRoundedIcon from '@mui/icons-material/LocalMallRounded';
// @mui icons
import Icon from "@mui/material/Icon";
import Home from "./layouts/home";
import CreateOrder from "./layouts/create-order";
import OrderAddressHandler from "./layouts/create-order/data/orderAddressHandler";
import SellerProfile from "layouts/sellers/profile";
import PrintIcon from '@mui/icons-material/Print';
import OrderDetails from "layouts/create-order/data/orderDetails";
import CustomOrder from "layouts/create-order/custom-order";

const routes = [
  {
    type: "examples",
    name: "خانه",
    key: "home",
    icon: <Icon fontSize="small">home</Icon>,
    route: "/home",
    component: <Home />,
  },
  // {
  //   type: "examples",
  //   name: "خانه",
  //   key: "presentation",
  //   icon: <Icon fontSize="small">home</Icon>,
  //   route: "/presentation",
  //   component: <Presentation />,
  // },
  {
    type: "collapse",
    name: "داشبورد",
    key: "dashboard",
    icon: <Icon fontSize="small">dashboard</Icon>,
    route: "/dashboard",
    component: <Dashboard />,
  },
  {
    type: "collapse",
    name: "دفاتر فنی",
    key: "sellers",
    icon: <PrintIcon></PrintIcon>,
    route: "/sellers",
    component: <Sellers />,
  },
  {
    type: "collapse",
    name: "سفارشات",
    key: "orders",
    icon: <LocalMallRoundedIcon></LocalMallRoundedIcon>,
    route: "/orders",
    component: <Orders />,
  },
  // {
  //   type: "collapse",
  //   name: "جدول ها",
  //   key: "tables",
  //   icon: <Icon fontSize="small">table_view</Icon>,
  //   route: "/tables",
  //   component: <Tables />,
  // },
  // {
  //   type: "collapse",
  //   name: "جدول قیمت ها",
  //   key: "Packages",
  //   icon: <Icon fontSize="small">shopping_cart</Icon>,
  //   route: "/Packages",
  //   component: <Packages />,
  // },
  {
    type: "auth",
    name: "ورود",
    key: "sign-in",
    icon: <Icon fontSize="small">login</Icon>,
    route: "/authentication/sign-in",
    component: <SignIn />,
  },
  {
    type: "collapse",
    name: "پروفایل",
    key: "user-profile",
    icon: <Icon fontSize="small">person</Icon>,
    route: "/user-profile",
    component: <UserProfile />,
  },
  {
    type: "auth",
    name: "ورود",
    key: "login",
    icon: <Icon fontSize="small">login</Icon>,
    route: "/auth/login",
    component: <Login />,
  },
  {
    route: "/sellers/:id/profile",
    key: "sellersProfile",
    component: <SellerProfile />,
  },
  {
    route: "/order/:id",
    component: <Order />,
  },
  {
    route: "/create-order",
    key: "createOrder",
    component: <CreateOrder/>,
  },
  {
    route: "/create-order/custom",
    key: "createCustomOrder",
    component: <CustomOrder/>,
  },
  {
      route: "/create-order/step2/:id",
    component: <OrderDetails/>,
  },
  {
      route: "/create-order/step3/:id",
    component: <OrderAddressHandler/>,
  },
];

export default routes;
