/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MDBox from "components/MDBox";

// Material Kit 2 React examples
import RotatingCard from "examples/Cards/RotatingCard";
import RotatingCardFront from "examples/Cards/RotatingCard/RotatingCardFront";
import RotatingCardBack from "examples/Cards/RotatingCard/RotatingCardBack";
import DefaultInfoCard from "examples/Cards/InfoCards/DefaultInfoCard";

// Images
import bgFront from "assets/images/copyimage3.jpg";
import bgBack from "assets/images/copyimage.jpg";
import {Link} from "react-router-dom";
import Icon from "@mui/material/Icon";
import MDTypography from "../../../components/MDTypography";

function Information() {
  return (
    <MDBox component="section">
        <Grid container item xs={12} spacing={3} alignItems="center">
          <Grid item xs={12} lg={4}>
            <RotatingCard>
              <RotatingCardFront
                image={bgFront}
                icon="touch_app"
                title={
                  <>
                    برای اونایی که
                    <br />
                    سفارش عجله ای دارن
                  </>
                }
                description="شروع عملیات چاپ در لحظه ی ثبت سفارش با ارسال سفارش به دفتر فنی فعال"
              />
              <RotatingCardBack
                  action={{ type: "internal", route: "/create-order", label: "ایجاد سفارش" }}
                image={bgBack}
                title="سرویس ارسال"
                description="برای اولین بار در ایران ارسال سفارشات شما در کمتر ۳۰ دقیقه در هر کجای ایران"
              />
            </RotatingCard>
          </Grid>
          <Grid item xs={12} lg={7} sx={{ ml: "auto" }}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <DefaultInfoCard
                  icon="content_copy"
                  title="چرا ایزی چاپ ؟"
                  description="از ثبت تا دریافت در کمترین زمان"
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <DefaultInfoCard
                  icon="price_change"
                  title="قیمت هاتون ؟"
                  description="با بهترین متریال و پایین ترین قیمت."
                />
              </Grid>
            </Grid>
            <Grid container spacing={3} sx={{ mt: { xs: 0, md: 6 } }}>
              <Grid item xs={12} md={6}>
                <DefaultInfoCard
                  icon="flip_to_front"
                  title="هر جای ایران ؟"
                  description="در هر کجای ایران با ارسال ۳۰ دقیقه ای."
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <DefaultInfoCard
                  icon="devices"
                  title="تخفیف دارید؟"
                  description="ارسال رایگان اولین سفارشتون"
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
    </MDBox>
  );
}

export default Information;
