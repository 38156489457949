/**
 =========================================================
 * Material Dashboard 2 React - v2.1.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-react
 * Copyright 2022 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

import {defaultLogo, numberFormat, SupportTelegramLink} from "../../services/helperFunctions";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";



import OrdersOverview from "layouts/dashboard/components/OrdersOverview";
import React, {useEffect, useState} from "react";
import OrderService from "../../services/client-order-service";
import PackageService from "../../services/package-service";
import Card from "@mui/material/Card";
import MDTypography from "../../components/MDTypography";
import PriceHandler from "../packages/data/priceHandler";
import FilledInfoCard from "../../examples/Cards/InfoCards/FilledInfoCard";
import PackageCard from '../../examples/Cards/StatisticsCards/PackageCard'
import { Modal } from "@mui/material";
import ComplexStatisticsCard from "examples/Cards/StatisticsCards/ComplexStatisticsCard";
import Swal from "sweetalert2";
import MDButton from "components/MDButton";
import { Link } from "react-router-dom";
function Dashboard() {
    const [orderCount, setOrderCount] = useState(0);
    const [sellerPackages, setSellerPackages] = useState([]);
    const [categories, setCategories] = useState([]);
    const [packages, setPackages] = useState([]);
    const [selectedParentCategory, setSelectedParentCategory] = useState(null);
    const [selectedPackage, setSelectedPackage] = useState(null);
    const [sellerPackagesBox, setSellerPackagesBox] = useState([]);
    const [firstChildCategories, setFirstChildCategories] = useState([]);
    const [selectedFirstChildCategory, setSelectedFirstChildCategory] = useState(null);
    const [secondChildCategories, setSecondChildCategories] = useState([]);
    const [selectedSecondChildCategory, setSelectedSecondChildCategory] = useState(null);
    
    const [thirdChildCategories, setThirdChildCategories] = useState([]);
    const [selectedThirdChildCategory, setSelectedThirdChildCategory] = useState(null);
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const fetchCategories = async () => {
        
        const response = await PackageService.categories();
        if(response.status == 'success'){
        
            setCategories(response.data)
        }
    };


    const categoryHandler = (e) => {
        setPackages([])
        setSelectedPackage(null)
        setFirstChildCategories([])
        setSelectedFirstChildCategory(null)
        setSecondChildCategories([])
        setSelectedSecondChildCategory(null)
        setThirdChildCategories([])
        setSelectedThirdChildCategory(null)
        setSelectedParentCategory(e.target.value)
    };


    const firstChildCategoriesHandler = (e) => {
        setPackages([])
        setSelectedPackage(null)
        setSecondChildCategories([])
        setSelectedSecondChildCategory(null)
        setThirdChildCategories([])
        setSelectedThirdChildCategory(null)
        setSelectedFirstChildCategory(e.target.value)
        // fetchCategoryPackages(e.target.value)
    };

    const secondChildCategoriesHandler = (e) => {

        setPackages([])
        setThirdChildCategories([])
        setSelectedPackage(null)
        setSelectedThirdChildCategory(null)
        setSelectedSecondChildCategory(e.target.value)
        // fetchCategoryPackages(e.target.value)
    };


    const thirdChildCategoriesHandler = (e) => {

        setPackages([])
        setSelectedPackage(null)
        setSelectedThirdChildCategory(e.target.value)
        // fetchCategoryPackages(e.target.value)
    };
    
    const openSellerModal = () => {
     
    
        if(selectedPackage){
            handleOpen()
            fetchPackageSellerPackages(selectedPackage)
        }else{
            Swal.fire("ابتدا دسته بندی و پکیج مورد نظر خود را انتخاب کنید");
        }
       
    };


    const packageHandler = (e) => {
     
        setSelectedPackage(e.target.value)
    };

    
    const fetchCategorySubCategories = async (categoryId) => {
        
        const response = await PackageService.categorySubCategories(categoryId);
        if(response.status == 'success'){
            console.log(response.data.children_recursive.length);
            
            if(response.data.children_recursive.length == 0){
                fetchCategoryPackages(categoryId)
            }else{
                if(selectedFirstChildCategory && selectedSecondChildCategory == null && selectedThirdChildCategory == null){
                    setSecondChildCategories(response.data.children_recursive)
                }else if(selectedFirstChildCategory && selectedSecondChildCategory && selectedThirdChildCategory == null){
    
                    setThirdChildCategories(response.data.children_recursive)
                }else if (selectedFirstChildCategory == null && selectedSecondChildCategory == null && selectedThirdChildCategory == null){
    
                    setFirstChildCategories(response.data.children_recursive)
                }
            }
            
        }
    };
    
    const fetchPackageSellerPackages = async (id) => {
        
        const response = await PackageService.packageSellersPackages(id);
        if(response.status == 'success'){
            setSellerPackages(response.data)
        }
    };
    
    const fetchCategoryPackages = async (id) => {
        
        const response = await PackageService.categoryPackages(id);
        if(response.status == 'success'){
            console.log(response.data);
            
            setPackages(response.data)
        }
    };

    const fetchOrderCount = async () => {
        const userData = localStorage.getItem('token'); // Use getItem, not key
        const response = await OrderService.orders(JSON.stringify(userData));
        setOrderCount(response.data.length)

    };


    useEffect(() => {
         
        if(sellerPackages.length > 0){
            setSellerPackagesBox(sellerPackages.map((sellerPackage) => {

              
                return <MDBox mb={4}>
                           <PackageCard
                                
                                   color={sellerPackage.saler.isOpen ? 'info' : 'error'} 
                                   logo={sellerPackage.saler.logo ?? defaultLogo}
                                   title={sellerPackage.title}
                                   rating={sellerPackage.saler.average_rate ?? 'جدید'}
                                   number={selectedParentCategory != 27 ? (numberFormat(sellerPackage.price.price)+ ' تومان') : ''}
                                   subTitle={sellerPackage.saler.name}
                                   description={sellerPackage.saler.address}
                                   buttonTitle={sellerPackage.saler.isOpen ? 'ثبت سفارش' : 'پیش سفارش'}
                                   badge={sellerPackage.saler.isOpen}
                                   buttonValue={sellerPackage}
                                   buttonLink={'/create-order?package='+sellerPackage.id+'&category='+selectedParentCategory+'&first-child-category='+selectedFirstChildCategory+'&second-child-category='+selectedSecondChildCategory}
                               />
                       </MDBox>
                }))
        }else{
            
            setSellerPackagesBox('پکیجی یافت نشد')
    
        }
       

      
    }, [sellerPackages]);

    

    useEffect(() => {

        if(selectedFirstChildCategory){
            
            fetchCategorySubCategories(selectedFirstChildCategory)
        }
    }, [selectedFirstChildCategory]);


    

    useEffect(() => {

        if(selectedSecondChildCategory){
            
            fetchCategorySubCategories(selectedSecondChildCategory)
        }
    }, [selectedSecondChildCategory]);



    useEffect(() => {

        if(selectedThirdChildCategory){
            
            fetchCategorySubCategories(selectedThirdChildCategory)
        }
    }, [selectedThirdChildCategory]);



    useEffect(() => {

        if(selectedParentCategory){

            fetchCategorySubCategories(selectedParentCategory)
        }
    }, [selectedParentCategory]);


    useEffect(() => {

    
        fetchCategories();
        fetchOrderCount();

    }, []);

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '90%', // Use a percentage for responsiveness
        maxWidth: '600px', // Set a max width for larger screens
        maxHeight: '80vh', // Limit height to viewport height
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 4,
        overflowY: 'auto', // Enable vertical scrolling
        overflowX: 'hidden', // Prevent horizontal scrolling
        '-ms-overflow-style': 'none', // For Internet Explorer and Edge
    };
    
    // For Webkit browsers (Chrome, Safari)
    const webkitStyle = {
      '&::-webkit-scrollbar': {
        display: 'none', // Hide scrollbar
      },
    };
    
    // Combine styles if using a styling solution that supports them
    const combinedStyle = {
      ...style,
      ...webkitStyle,
    };
      
    return (
        <DashboardLayout>
            <DashboardNavbar/>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                >
                    <Card sx={combinedStyle}>
                        {sellerPackagesBox}
                    </Card>
            </Modal>
            <MDBox py={3}>

                    <Grid container spacing={3}>
                        {/* <Grid item xs={12} lg={8}>
                            <MDBox mb={1.5}>
                                <Card>
                                    {/* <MDBox
                                        mx={2}
                                        mt={-1}
                                        py={2}
                                        px={2}
                                        variant="gradient"
                                        bgColor="secondary"
                                        borderRadius="lg"
                                        coloredShadow="secondary"
                                    >
                                        <MDTypography variant="h6" color="white">
                                            احتساب هزینه
                                        </MDTypography>
                                    </MDBox> */}
                                    {/* <MDBox py={5}>
                                        <PriceHandler 
                                        categoryHandler={categoryHandler}
                                        packageHandler={packageHandler}
                                        openSellerModal={openSellerModal}
                                        categories={categories}
                                        firstChildCategories={firstChildCategories}
                                        firstChildCategoriesHandler={firstChildCategoriesHandler}
                                        secondChildCategories={secondChildCategories}
                                        secondChildCategoriesHandler={secondChildCategoriesHandler}
                                        thirdChildCategories={thirdChildCategories}
                                        thirdChildCategoriesHandler={thirdChildCategoriesHandler}
                                         packages={packages}
                                         />
                                    </MDBox> */}
                                {/* </Card>
                            </MDBox> */}
                        {/* </Grid> */}
                        <Grid item xs={12} md={12} lg={6}>
                            <Card p={2}>
                                <MDBox
                                    mx={2}
                                    mt={-1}
                                    py={2}
                                    px={2}
                                    variant="gradient"
                                    bgColor="warning"
                                    borderRadius="lg"
                                    coloredShadow="success"
                                >
                                    <MDTypography variant="h6" color="white">
                                        سفارش خودت و ثبت کن
                                    </MDTypography>
                                </MDBox>
                                <MDBox py={2} px={3} sx={{display : 'block'}}>
                                    <MDTypography variant="body2" color="text">
                                        توی ایزی چاپ انتخاب با توعه
                                    </MDTypography>
                                </MDBox>
                                <MDBox px={3} sx={{display : 'block'}}>
                                    <MDTypography variant="caption" color="text">
                                        پرینت جزوه ٫ بنر ٫فنر زنی کتابت و ...
                                    </MDTypography>
                                </MDBox>
                                <MDBox px={3} sx={{display : 'block'}}>
                                <MDTypography variant="caption" color="text">
                                        به راحتی سفارشت رو ثبت کن دیگ نیازی به انتظار نیست
                                    </MDTypography>
                                </MDBox>
                                <MDBox px={3} sx={{display : 'block'}}>
                                <MDTypography variant="caption" color="text">
                                        ایزی چاپ سفارشت و برات میاره
                                    </MDTypography>
                                </MDBox>
                                   
                                <MDBox py={2} px={3}>
                                    <MDButton
                                        component={Link}
                                        to={"/create-order"}
                                        href={(e) => e.preventDefault()}
                                        target=""
                                        fullWidth
                                        rel={"noreferrer"}
                                        color="warning"
                                    >ایجاد سفارش</MDButton>
                                </MDBox>
                            </Card>
                        </Grid>

                        <Grid item xs={12} md={12} lg={6}>
                            <FilledInfoCard
                                variant="gradient"
                                color="info"
                                icon="help_center"
                                title="پشتیبانی"
                                description="برای گرفتن راهنمایی یا هر سوالی که داری توی پشتیبانی ما در سریع ترین زمان پاسخ داده میشه"
                                action={{
                                    type: "external",
                                    route: SupportTelegramLink,
                                    label: "پیام به پشتیبانی",
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} md={6} lg={3}>
                            <MDBox mb={1.5}>
                                <ComplexStatisticsCard
                                    color="success"
                                    icon="done"
                                    title="سفارشات قبلی"
                                    count={orderCount}
                                    percentage={{
                                        color: "success",
                                        amount: "+3%",
                                        label: "than last month",
                                    }}
                                />
                            </MDBox>
                        </Grid>
                        <Grid item xs={12} md={6} lg={3}>
                            <MDBox mb={1.5}>
                                <ComplexStatisticsCard
                                    icon="local_mall"
                                    title="سفارشات در جریان"
                                    count={orderCount}
                                    percentage={{
                                        color: "success",
                                        amount: "+3%",
                                        label: "than last month",
                                    }}
                                />
                            </MDBox>
                        </Grid>
                        <Grid item xs={12} lg={4}>
                            <OrdersOverview />
                        </Grid>
                    </Grid>
            </MDBox>
        </DashboardLayout>
    );
}

export default Dashboard;
