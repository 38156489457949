

// @mui material components
import Card from "@mui/material/Card";

// Author page sections
import Profile from "./data/Profile";
import Posts from "./data/Posts"
import Grid from "@mui/material/Grid";

// Images
import MDBox from "components/MDBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import * as React from 'react';
import sellerService from "services/seller-service";
import { numberFormat } from "services/helperFunctions";
import FilledInfoCard from "examples/Cards/InfoCards/FilledInfoCard";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { Accordion, AccordionDetails, AccordionSummary, CardHeader, FormControl, InputLabel, MenuItem, Select, Skeleton, TextField, Typography, useMediaQuery } from "@mui/material";
import { ExpandMore } from "@mui/icons-material";
import packageService from "services/package-service";

function SellerProfile() {
  const { id } = useParams();
  const [sellerPackagesLoading, setSellerPackagesLoading] = useState(true);
  const [sellerPackages, setSellerPackages] = useState([]);
  const [seller, setSeller] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [categories, setCategories] = useState([]);
  const [expanded, setExpanded] = useState({});
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  const fetchSellerPackages = async (id) => {
  
    try {
      const response = await sellerService.sellerPackages(id, selectedCategory);
      if (response.data.status == 'error') {
      } else {
        setSellerPackagesLoading(false)
        setSellerPackages(response.data)
      }
    } catch (error) {
    }
  };

  const fetchSeller = async (id) => {
    
    try {
      const response = await sellerService.seller(id);
      if (response.data.status == 'error') {
      } else {
        
        setSeller(response.data);
      }
    } catch (error) {
    }
  };

  const fetchCategories = async () => {
    
    try {
      const response = await packageService.categories();
      if (response.data.status == 'error') {
      } else {
        if(response.data.length > 0 ){
         let categoryItems = response.data.map((category) => (
            <MenuItem key={category.id} value={category.id}>
              {category.title}
            </MenuItem>
          ))
          categoryItems.unshift(
            <MenuItem key={0} value={null}>
              همه موارد
            </MenuItem>
          );
          setCategories(categoryItems);
        }
        
      }
    } catch (error) {
    }
  };


  const categoryHandler = (e) =>{
    
    setSelectedCategory(e.target.value)
  }
  const searchHandler = (e) =>{
    console.log(e.target.value);
    
  }
  
const SellerPackagesBox = ({ sellerPackages }) => {


  const renderSellerPackage = (sellerPackage) => {      
    return(<Grid item xs={6} md={4} lg={3} key={sellerPackage.id}>
      
      <FilledInfoCard
        key={sellerPackage.id}
        title={sellerPackage.title}
        variant="contained"
        color="info"
        icon="local_offer_icon"
        description={numberFormat(sellerPackage.price.price) + ' تومان'}
        action={{
          type: "internal",
          // route: "/create-order?package=" + sellerPackageItem.id + '&category=' + category.id,
          route: "/create-order?package=" + sellerPackage.id ,
          color: "dark",
          label: "سفارش",
        }}
        />
    </Grid>)  
    
  }
  console.log(sellerPackages);
  

  return (
    <Grid container spacing={3}>
      {sellerPackages.length>0 ? sellerPackages.map(renderSellerPackage) : (

      <Grid item xs={12}>

        <MDBox p={4}>

        <Typography  sx={{ width: '30%', flexShrink: 0, fontSize: { xs: '0.8rem', sm: '0.9rem', lg: '1rem' }, lineHeight: { xs: 1.5, sm: 1.6, md: 1.7, lg: 1.8 } }}>
            پکیجی یافت نشد
          </Typography>
        </MDBox>
      </Grid>
      )}
    </Grid>
  );
}


useEffect(() => {
  setSellerPackagesLoading(true)
  fetchSellerPackages(id, selectedCategory);
}, [selectedCategory]);   

useEffect(() => {
  fetchSellerPackages(id);
  fetchSeller(id)
  fetchCategories()
}, []);   

  return (
  <DashboardLayout>
    <DashboardNavbar/>
      <MDBox 
           bgColor="white">
        <MDBox
          minHeight="13rem"
          width="100%"
          sx={{
            backgroundImage: ({ functions: { linearGradient, rgba } }) =>
              linearGradient(
                '#399fd1',
                '#fcbbcf'
              ),
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          }}
        />
        <Card
          sx={{
            p: 2,
            mx: { xs: 2, lg: 3 },
            mt: -8,
            mb: 4,
            backgroundColor: ({ palette: { white }, functions: { rgba } }) => rgba(white.main, 0.8),
            backdropFilter: "saturate(200%) blur(30px)",
            boxShadow: ({ boxShadows: { xxl } }) => xxl,
          }}
        >
          {seller.id ? (
            <Profile 
            logo={seller.logo}
            title={seller.name}
            rating={seller.average_rate ?? 'تازه وارد'}
            description={`${seller.summary} <br> آدرس دفتر فنی: ${seller.address}`}
            // description={`آدرس دفتر فنی: ${seller.address} <br>  شماره تماس: ${seller.mobile} `}
            />
          ) : (
            <>
            <CardHeader
                          avatar={
                            <Skeleton animation="wave" variant="circular" width={40} height={40} />}
                          title={<Skeleton
                            animation="wave"
                            height={30}
                            width="80%"
                            style={{ marginBottom: 6 }}
                          />}
                          subheader={
                            <Skeleton animation="wave" height={10} width="40%" />}
                        />
                      
                        <Skeleton sx={{ height: 190 }} animation="wave" variant="rectangular" />
            </>
          )}
        </Card>

        <Card
          sx={{
            p: 2,
            boxShadow: ({ boxShadows: { xxl } }) => xxl,
          }}
        >
           <Grid container spacing={2} mb={2}> 
           <Grid item xs={12}>
            <Typography variant="caption">
                فیلتر
              </Typography>
           </Grid>
              <Grid item xs={6}>
                <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">
                    دسته بندی
                    </InputLabel>
                        <Select
                        lineHeight={1.25}
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            label='دسته بندی'
                            name ="cafirtegory"
                            onChange={categoryHandler}
                        >
                          
                          {categories}
                        </Select>
                    </FormControl>
              </Grid>
              {/* <Grid item xs={6}>
                <FormControl fullWidth>
                    <TextField
                            onChange={searchHandler} name="search" id="outlined-basic" label="جستجو" variant="outlined" autoCorrect={false} autoComplete={false}/>
                    </FormControl>
              </Grid> */}
           </Grid>
        
        {sellerPackagesLoading ? (

            <Grid container spacing={2} mb={4}> 
              <Grid item xs={6} md={4}>
                <CardHeader
                            title={<Skeleton
                              animation="wave"
                              height={30}
                              width="80%"
                              style={{ marginBottom: 6 }}
                            />}
                          />
                        
                          <Skeleton sx={{ height: 190 }} animation="wave" variant="rectangular" />
                </Grid>
                <Grid item xs={6} md={4}>
                <CardHeader
                              title={<Skeleton
                                animation="wave"
                                height={30}
                                width="80%"
                                style={{ marginBottom: 6 }}
                              />}
                            />
                          
                            <Skeleton sx={{ height: 190 }} animation="wave" variant="rectangular" />
                </Grid>
              </Grid>
        ) : (

          <SellerPackagesBox  sellerPackages={sellerPackages}/>
        )}
       
        </Card>
      </MDBox>
    </DashboardLayout>
  );
}

export default SellerProfile;
