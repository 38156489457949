import HttpService from "./htttp.service";

class SellerService {

  allSellers = async (page = null) => {
  
    var allSellersEndpoint = 'client/sellers';
    if(page){

       allSellersEndpoint += '?page='+page;
    }
    return await HttpService.get(allSellersEndpoint);
  };

  newSellers = async (page = null) => {
  
    var newSellersEndpoint = 'client/sellers?orderBy=id&sortedBy=desc';
    if(page){

      newSellersEndpoint += '?page='+page;
    }
    return await HttpService.get(newSellersEndpoint);
  };

  sellerPackages = async (id, categoryId) => {
  
    if(categoryId){

      var sellerPackagesEndpoint = 'client/seller-packages/'+id+'?categoryId='+categoryId;
    }else{

    var sellerPackagesEndpoint = 'client/seller-packages/'+id;
    }
  
    
    return await HttpService.get(sellerPackagesEndpoint);
  };





  seller = async (id) => {
  
    var sellerEndpoint = 'client/sellers/'+id;
    
    return await HttpService.get(sellerEndpoint);
  };

}

export default new SellerService();
