
// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import Icon from "@mui/material/Icon";

import StarIcon from '@mui/icons-material/Star';
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";
import { Link } from "react-router-dom";
import MDButton from "components/MDButton";
import { Chip } from "@mui/material";

function ComplexStatisticsCard({ title, number, rating, badge, color, description, logo, subTitle, buttonTitle, buttonAction, buttonValue, buttonLink}) {

  
  return (
    <Card>
      <MDBox display="flex" justifyContent="space-between" pt={1} px={2}>
        <MDBox textAlign="center">
            <MDBox display="flex" >
                <MDAvatar src={logo} alt="Burce Mars" size="l" shadow="l" />
                <MDTypography p={2} component="span" variant="button">
                        <span>{rating}&nbsp;</span>
                        <StarIcon color='primary' />
                 </MDTypography>
           </MDBox>

           {badge == false && (
                  <MDBox display="flex" p={1}>

                  <Chip color="error" size="small" variant="outlined" label='بسته است'/>
                  </MDBox>
                )}
        </MDBox>
    
        <MDBox textAlign="right" lineHeight={1.25}>
            <MDTypography variant="button" fontWeight="light" color="text">
            {title}
          </MDTypography>
          <MDTypography variant="h4">{number}</MDTypography>
        </MDBox>
      </MDBox>
      <Divider />
      <MDBox display="flex" justifyContent="space-between" pt={1} px={2}>
        <MDBox pb={2} px={2}>
            <MDTypography component="p" variant="button" color="text" display="flex">
            &nbsp;{subTitle}
            </MDTypography>
            <MDTypography variant="caption" color="text" display="flex">
            &nbsp;{description}
            </MDTypography>
        </MDBox>
   
        <MDBox pb={2} px={2}>
     
            {buttonLink ? (
              <Link to={buttonLink}>
                <MDButton variant="outlined" color={color} size="small">
                  {buttonTitle}
                </MDButton>      
              </Link>
            ) : ( 
              <MDButton variant="outlined" onClick={() => buttonAction(buttonValue)} color={color} size="small">
                {buttonTitle}
              </MDButton>   
            )}
            
        </MDBox>
      </MDBox>
     
    </Card>
  );
}

// Setting default values for the props of ComplexStatisticsCard


// Typechecking props for the ComplexStatisticsCard
ComplexStatisticsCard.propTypes = {
  color: PropTypes.oneOf([
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "light",
    "dark",
  ]),
  title: PropTypes.string.isRequired,
};

export default ComplexStatisticsCard;
