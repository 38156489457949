import HttpService from "./htttp.service";

class OrderService {

  orders = async (payload) => {

    const ordersEndpoint = 'orders-show';
    return await HttpService.post(ordersEndpoint, payload);
  };

    payment = async (token) => {

    const paymentEndpoint = 'payment?token='+token;
    return await HttpService.get(paymentEndpoint, );
  };

  todos = async (payload) => {

    const ordersEndpoint = 'orders-todos';
    return await HttpService.post(ordersEndpoint, payload);
  };

  submitComment = async (payload) => {
    const submitCommentEndpoint = 'client/comments';
    return await HttpService.post(submitCommentEndpoint, payload);
  };
  
  order = async (payload, id) => {
    const orderEndpoint = 'client/orders/'+id;
    return await HttpService.get(orderEndpoint, payload);
  };
    uploadFile = async (payload) => {
    const uploadFileEndpoint = 'uploadFile';
    return await HttpService.post(uploadFileEndpoint, payload);
  };
    createOrder = async (payload) => {
    const createOrderEndpoint = 'order-items';
    return await HttpService.post(createOrderEndpoint, payload);
  };
  snappGetRide = async (credentials) => {
    const snappGetRideEndpoint = 'https://customer.snapp-box.com/v1/customer/create_order';
    return await HttpService.post(snappGetRideEndpoint, credentials);
  };

  logout = async () => {
    const logoutEndpoint = 'logout';
    return await HttpService.post(logoutEndpoint);
  };

  forgotPassword = async (payload) => {
    const forgotPassword = 'password-forgot';
    return await HttpService.post(forgotPassword, payload);
  }

  resetPassword = async (credentials) => {
    const resetPassword = 'password-reset';
    return await HttpService.post(resetPassword, credentials);
  }

  getProfile = async() => {
    const getProfile = 'me';
    return await HttpService.get(getProfile);
  }

  updateProfile = async (newInfo) => {
    const updateProfile = "me";
    return await HttpService.patch(updateProfile, newInfo);
  }
}

export default new OrderService();
