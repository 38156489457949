// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import {CircularProgress, Select, Skeleton} from "@mui/material";
import {MenuItem} from "@mui/material";
import {InputLabel} from "@mui/material";
import {FormControl} from "@mui/material";
import {TextField} from "@mui/material";

// Material Kit 2 React components
import MDBox from "components/MDBox";


// Images
import React, {useEffect, useState} from "react";
import MDTypography from "../../../components/MDTypography";
import MDButton from "../../../components/MDButton";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import {styled} from "@mui/material/styles";
import { numberFormat } from "services/helperFunctions";
import { Description } from "@mui/icons-material";


const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
});
function OrderHandler({
    categories,
    selectLoading,
    packages,
    sellerPackageButtonColor,
    price,
    selectedFirstChildCategory,
    selectedSecondChildCategory,
    firstChildCategories,
    firstChildCategoriesHandler,
    secondChildCategories,
    secondChildCategoriesHandler,
    thirdChildCategories,
    thirdChildCategoriesHandler,
    selectedForthChildCategory,
    forthChildCategories,
    forthChildCategoriesHandler,
    rangeHanlder,
    fileUploadHandler,
    fileLoading,
    orderCreateHandler,
    file,
    categoryHandler,
    packageHandler, 
    copyCountHandler,
    openSellerModal,
    selectedSellerPackage,
    selectedCategory,
    selectedPackage,
    descriptionHandler,
    description,
    copyCount
}) {

    const [categoryBox, setCategoryBox] = useState([]);
    const [packageBox, setPackageBox] = useState([]);
    const [fileBox, setFileBox] = useState(false);
    const [rangeBox, setRangeBox] = useState(false);
    const [copyCountBox, setCopyCountBox] = useState(true);
    const [descriptionBox, setDescriptionBox] = useState(false);
    const [countLabel, setCountLabel] = useState("تعداد");
    const [firstChildCategoriesBoxes, setFirstChildCategoriesBoxes] = useState([]);
    const [firstChildCategoryTitle, setFirstChildCategoryTitle] = useState('');
    const [secondChildCategoryTitle, setSecondChildCategoryTitle] = useState('');
    const [thirdChildCategoryTitle, setThirdChildCategoryTitle] = useState('');
    const [secondChildCategoriesBoxes, setSecondChildCategoriesBoxes] = useState([]);
    const [thirdChildCategoriesBoxes, setThirdChildCategoriesBoxes] = useState([]);
    const [forthChildCategoryTitle, setForthChildCategoryTitle] = useState('');
    const [forthChildCategoriesBoxes, setForthChildCategoriesBoxes] = useState([]);
  
 

    useEffect(() => {
        if(forthChildCategories.length > 0){
            
            setForthChildCategoriesBoxes(forthChildCategories.map((forthChildCategory) => {

                return <MenuItem value={forthChildCategory.id}>{forthChildCategory.title}</MenuItem>
             }))
        }else{
            setForthChildCategoriesBoxes([])
        }
       

    }, [forthChildCategories]);
 

    useEffect(() => {
        if(thirdChildCategories.length > 0){
            
            setThirdChildCategoriesBoxes(thirdChildCategories.map((thirdChildCategory) => {

                return <MenuItem value={thirdChildCategory.id}>{thirdChildCategory.title}</MenuItem>
             }))
        }else{
            setThirdChildCategoriesBoxes([])
        }
       

    }, [thirdChildCategories]);


    useEffect(() => {
        if(secondChildCategories.length > 0){
            
            setSecondChildCategoriesBoxes(secondChildCategories.map((secondChildCategory) => {

                return <MenuItem value={secondChildCategory.id}>{secondChildCategory.title}</MenuItem>
             }))
        }else{
            setSecondChildCategoriesBoxes([])
        }
       

    }, [secondChildCategories]);


    useEffect(() => {
        console.log(firstChildCategories);
        
        if(firstChildCategories.length > 0){
            if(firstChildCategories[0].id){
                setFirstChildCategoriesBoxes(firstChildCategories.map((firstChildCategory) => {

                    return <MenuItem value={firstChildCategory.id}>{firstChildCategory.title}</MenuItem>
                 }))
            }
            
        }else{
            setFirstChildCategoriesBoxes([])
        }
       

    }, [firstChildCategories]);
  

    

    
    useEffect(() => {
        
        if(packages.length > 0){
            setPackageBox(packages.map((packageItem) => {

                return <MenuItem value={packageItem.id} >{packageItem.title}</MenuItem>
             }))
        }else{
            
            setPackageBox(<MenuItem value={null} >موردی یافت نشد</MenuItem>)
        }
       

    }, [packages]);

    useEffect(() => {
        
        if(categories.length > 0){
            setCategoryBox(categories.map((category) => {
                return !category.is_custom ? (
                    <MenuItem key={category.id} value={category.id}>
                        {category.title}
                    </MenuItem>
                ) : null; 
             }))
        }else{
            setCategoryBox(<MenuItem value={null} >لطفا صبر کنید</MenuItem>)
        }
       

    }, [categories]);


    const handleropenSellerModal =  () => {

        openSellerModal(selectedPackage)
    };



    useEffect(() => {
      console.log(selectedCategory);
      
        
        switch (selectedCategory){
         case 1 : 
            //Print Category
             setFirstChildCategoryTitle('نوع پرینت')
             setSecondChildCategoryTitle('جنس برگه')
             setThirdChildCategoryTitle('سایز')
             setFileBox(true)
             setCountLabel("تعداد نسخه از کل فایل")
             setRangeBox(true)
         
         break;
         case 2 : 
            setFileBox(true)
            setFirstChildCategoryTitle('نوع بنر')
            setCountLabel("تعداد بنر")
            setRangeBox(false)
         
         break;
         case 3 : 
            setFileBox(false)
            setFirstChildCategoryTitle('نوع کارت ویزیت')
            setCountLabel("تعداد کارت ویزیت")
            setRangeBox(false)
         
         break;
         case 4 : 
             
            setFileBox(false)
            setFirstChildCategoryTitle('نوع صحافی')
            setCountLabel("تعداد کتاب های فنر زنی")
            setRangeBox(false)
         
         break;
         case 5 : 
             
            setFileBox(true)
            setFirstChildCategoryTitle('جنس آگهی ترحیم')
            setCountLabel("تعداد آگهی ترحیم")
            setRangeBox(false)
         
         break;
         case 6 : 
             
            setFileBox(true)
            setFirstChildCategoryTitle('نوع پلات')
            setCountLabel("تعداد پلات")
            setRangeBox(false)
         
         break;
         case 27 : 
            setCopyCountBox(false)
            setRangeBox(false)
            setDescriptionBox(true)
            setFileBox(true)
         
         break;
        }
         
     }, [selectedCategory]);
    

    
  return (
      <Container>
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <MDBox
                    display="flex"
                    flexDirection="column"
                    mt={2}
                    width="100%">
                    <FormControl fullWidth>
                        <InputLabel shrink={selectedCategory !== ""}>دسته بندی</InputLabel>
                        <Select
                            id="demo-simple-select"
                            label="دسته بندی"
                            value={selectedCategory}
                            onChange={categoryHandler}
                        >
                          {categoryBox}
                        </Select>
                    </FormControl>
                </MDBox>
            </Grid>
            {firstChildCategoriesBoxes.length > 0 && (<Grid item xs={12} md={12}>
                  <MDBox
                    display="flex"
                    flexDirection="column"
                    alignItems="flex-start"
                    width="100%">
                    <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label" shrink={selectedCategory !== ""}>
                          {firstChildCategoryTitle ?? 'در حال جستجو'}
                        </InputLabel>
                        <Select
                        lineHeight={1.25}
                            labelId="demo-simple-select-label"
                            value={selectedFirstChildCategory}
                            id="demo-simple-select"
                            label={firstChildCategoryTitle ?? 'در حال جستجو'}
                            name ="cafirtegory"
                            onChange={firstChildCategoriesHandler}
                        >
                          
                          {firstChildCategoriesBoxes}
                        </Select>
                    </FormControl>
                </MDBox>   
            </Grid>)}



            {secondChildCategories.length > 0 && (<Grid item xs={12} md={12}>
                  <MDBox
                    display="flex"
                    flexDirection="column"
                    alignItems="flex-start"
                    width="100%">
                    <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label" shrink={selectedCategory !== ""}>
                    {secondChildCategoryTitle ?? 'در حال جستجو'} 
                    </InputLabel>
                        <Select
                        lineHeight={1.25}
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            label=   {secondChildCategoryTitle ?? 'در حال جستجو'}
                            name ="cafirtegory"
                            value={selectedSecondChildCategory}
                            onChange={secondChildCategoriesHandler}
                        >
                          
                          {secondChildCategoriesBoxes}
                        </Select>
                    </FormControl>
                </MDBox>   
            </Grid>)}

            {thirdChildCategories.length > 0 && (<Grid item xs={12} md={12}>
                  <MDBox
                    display="flex"
                    flexDirection="column"
                    alignItems="flex-start"
                    width="100%">
                    <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">
                    {thirdChildCategoryTitle ?? 'در حال جستجو'} 
                    </InputLabel>
                        <Select
                        lineHeight={1.25}
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            label=   {thirdChildCategoryTitle ?? 'در حال جستجو'}
                            name ="cafirtegory"
                            onChange={thirdChildCategoriesHandler}
                        >
                          
                          {thirdChildCategoriesBoxes}
                        </Select>
                    </FormControl>
                </MDBox>   
            </Grid>)}


            {forthChildCategories.length > 0 && (<Grid item xs={12} md={12}>
                  <MDBox
                    display="flex"
                    flexDirection="column"
                    alignItems="flex-start"
                    width="100%">
                    <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">
                    {forthChildCategoryTitle ?? 'در حال جستجو'} 
                    </InputLabel>
                        <Select
                        lineHeight={1.25}
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            label=   {forthChildCategoryTitle ?? 'در حال جستجو'}
                            name ="cafirtegory"
                            onChange={forthChildCategoriesHandler}
                        >
                          
                          {forthChildCategoriesBoxes}
                        </Select>
                    </FormControl>
                </MDBox>   
            </Grid>)}

            <Grid item xs={12}>
              <MDBox
                    display="flex"
                    flexDirection="column"
                    alignItems="flex-start"
                    width="100%">
                    <FormControl fullWidth>
                        <InputLabel  shrink={selectedPackage !== ""}>چی میخای ؟</InputLabel>
                        <Select
                            id="demo-simple-select"
                            label="چی میخای ؟"
                            value={selectedPackage}
                            name ="size"
                            onChange={packageHandler}
                        >
                          {selectLoading ? <MenuItem value={null} >لطفا صبر کنید</MenuItem> : (packageBox)}
                        </Select>
                    </FormControl>
              </MDBox>
            </Grid>

            {selectedSellerPackage.id ? (
                <Grid item xs={12} md={12}>
                        <MDBox 
                        py={2} 
                        display="flex" 
                        flexDirection="column" 
                        alignItems="flex-start" 
                        justifyContent="center" 
                        sx={{ 
                            border: '1px solid #e0e0e0', 
                            borderRadius: '8px', 
                            padding: '16px', 
                            backgroundColor: '#f9f9f9', 
                            boxShadow: 2 
                        }}
                    >
                           {selectedCategory != 27 ? (
                             <>
                             <MDTypography variant="h6" color="primary">
                                 {selectedSellerPackage.title}
                             </MDTypography>
                             <MDTypography variant="subtitle1" color="textSecondary">
                                 {selectedSellerPackage.saler ? selectedSellerPackage.saler.name : 'یافت نشد'}
                             </MDTypography>
                             
                             <MDBox display="flex" flexDirection="column" mt={1}>
                                 <MDTypography variant="body1" color="secondary" sx={{ fontWeight: 'bold' }}>
                                     <MDTypography variant="caption" color="textSecondary" sx={{ fontWeight: 'normal' }}>
                                     مبلغ :   &nbsp;
                                     </MDTypography>
                                     {selectedSellerPackage.price ? numberFormat(selectedSellerPackage.price.price) + ' تومان' : 'یافت نشد'}
                                 </MDTypography>
                                 <MDTypography variant="body1" color="secondary" sx={{ fontWeight: 'bold' }}>
                                     <MDTypography variant="caption" color="textSecondary" sx={{ fontWeight: 'normal' }}>
                                     تعداد :   &nbsp;
                                     </MDTypography>
                                     {copyCount ?? 'تعیین نشده'}
                                 </MDTypography>
 
                                 <MDTypography variant="body1" color="secondary" sx={{ fontWeight: 'bold', mt: 1 }}>
                                     <MDTypography variant="caption" color="textSecondary" sx={{ fontWeight: 'normal' }}>
                                     مبلغ کل :  &nbsp;
                                     </MDTypography>
                                     {price ? numberFormat(price) + ' تومان' : 'وابسته به تعداد'}
                                 </MDTypography>
                             </MDBox>
                             </>
                           ) : (
                            <>
                            <MDTypography variant="h6" color="primary">
                                {selectedSellerPackage.title}
                            </MDTypography>
                            <MDTypography variant="subtitle1" color="textSecondary">
                                {selectedSellerPackage.saler ? selectedSellerPackage.saler.name : 'یافت نشد'}
                            </MDTypography>
                            
                            <MDBox display="flex" flexDirection="column" mt={1}>
                                <MDTypography variant="body1" color="secondary" sx={{ fontWeight: 'bold' }}>
                                    <MDTypography variant="caption" color="textSecondary" sx={{ fontWeight: 'normal' }}>
                                    تعداد :   &nbsp;
                                    </MDTypography>
                                    {copyCount ?? 'تعیین نشده'}
                                </MDTypography>
                            </MDBox>
                            </>
                           )}
                        </MDBox>
                </Grid>
            ) : ('')}

            {rangeBox ? (
                <Grid item xs={12} >
                      <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">
                     تعداد 
                    </InputLabel>
                        <Select
                        lineHeight={1.25}
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            label="تعداد"
                            name ="range_id"
                            onChange={rangeHanlder}
                        >
                            <MenuItem value={1}>1 تا 20</MenuItem>
                            <MenuItem value={2}>21 تا 50</MenuItem>
                            <MenuItem value={3}>51 تا 200</MenuItem>
                            <MenuItem value={4}>201 تا 500</MenuItem>
                            <MenuItem value={5}>501 تا 1000</MenuItem>
                            <MenuItem value={6}>1000 به بالا</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
              ) : ''}

              {fileBox ? (
                  <Grid item xs={12} >
                  <MDBox display='flex'>
                      <MDBox pr={1}>
                      <MDButton component="label" variant="contained" color={file.uploaded ? 'success' : 'info'}  startIcon={<CloudUploadIcon />}>
                          {fileLoading ? (
                              <CircularProgress size={24} color="inherit" />
                          ) : (
                              file.uploaded ? file.ext : "فایل خود را انتخاب کنید"
                          )}
                          
                          <VisuallyHiddenInput onChange={fileUploadHandler} type="file" />
                      </MDButton>
                              {file.error && file.error.map((option) => (
                                  <MDTypography variant="body2" color="text">
                                      {option}
                                  </MDTypography>
                              ))}
                      </MDBox>
                     
                      {file.uploaded  &&
                         <MDButton color="success" component="label" variant="contained">
                               {"تعداد صفحات فایل شما : "+file.pageCount}
                           </MDButton>
                      }
                  </MDBox>
                  <MDBox>
                  <MDTypography fontWeight='light' variant="caption" color="text">
                            فایل های مجاز pdf ,txt ,jpg ,png ,xlsx ,jpeg ,webp ,gif ,zip ,rar
                    </MDTypography>
                  </MDBox>
              </Grid>
              ) : ''}


           
            

           {copyCountBox && (
            <Grid item xs={12} md={12}>
                <MDBox
                    display="flex"
                    flexDirection="column"
                    alignItems="flex-start"
                    width="100%">
                    <FormControl fullWidth>
                        <TextField
                            onChange={copyCountHandler} value={copyCount} name="copyCount" id="outlined-basic" label={countLabel} variant="outlined" />
                    </FormControl>
                </MDBox>
            </Grid>
           )} 

           {descriptionBox && (
            <Grid item xs={12} md={12}>
                <MDBox
                    display="flex"
                    flexDirection="column"
                    alignItems="flex-start"
                    width="100%">
                    <FormControl fullWidth>
                    <TextField
                        id="description"
                        label="توضیحات"
                        onChange={descriptionHandler}
                        value={description || "متریال : \nدرصد تخلخل  :‌"} // Use value to set default multiline text
                        multiline
                        rows={4}
                    />

                    </FormControl>
                </MDBox>
            </Grid>
           )}

            <Grid item xs={12} md={12}>
                <MDBox
                    display="flex"
                    flexDirection="column"
                    alignItems="flex-start"
                   width="100%">
                    <FormControl fullWidth>

                    <MDButton onClick={handleropenSellerModal} variant="contained" color={sellerPackageButtonColor}>

                    {selectedCategory !== 27 ? 
                    (selectedSellerPackage.id ? 'تعویض دفتر فنی' : 'انتخاب دفتر فنی') : 
                    (selectedSellerPackage.id ? 'تعویض اپراتور' : 'انتخاب اپراتور')}
                    </MDButton>
                    </FormControl>
                </MDBox>
            </Grid>

            <Grid item xs={12} md={12}>
            {selectedSellerPackage.id ? (<MDBox
                    display="flex"
                    flexDirection="column"
                    alignItems="flex-start"
                   width="100%">
                    <FormControl fullWidth>
                      <MDButton onClick={orderCreateHandler} variant="contained" color='info' loading>
                        {selectedCategory != 27 ? 'تایید و ادامه' : 'ثبت سفارش'}
                      </MDButton>
                    </FormControl>
                </MDBox>) : '' }
                
            </Grid>

        </Grid>          
      </Container>
  );
}

export default OrderHandler;
