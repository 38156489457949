/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import StarIcon from '@mui/icons-material/Star';

// Material Kit 2 React components
import MDAvatar from "components/MDAvatar";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox";
import { Link } from "react-router-dom";

function Profile({rating, description, title, logo}) {
  return (
    <MDBox component="section" py={3}>
      <Container>
        <Grid container item xs={12} justifyContent="center" mx="auto">
          <MDBox mt={{ xs: -16, md: -20 }} textAlign="center">
            <MDAvatar src={logo} alt="Burce Mars" size="xxl" shadow="xl" />
          </MDBox>
          <Grid container justifyContent="center">
            <Grid item xs={12} md={7} mx={{ xs: "auto", sm: 6, md: 1 }}>
              <MDBox display="flex" justifyContent="space-between" alignItems="center" mb={1}>
                <MDTypography variant="h3">{title}</MDTypography>
                <Link to='/create-order'>
                  <MDButton variant="outlined" color="info" size="small">
                    سفارش
                  </MDButton>
                </Link>
              </MDBox>
              <Grid container spacing={1}>
                <Grid item>
                  <MDTypography component="span" variant="body2" fontWeight="bold">
                   تعداد سفارشات : &nbsp;
                  </MDTypography>
                  <MDTypography component="span" variant="body2" color="text">  
                    عضو جدید 
                  </MDTypography>
                </Grid>
                <Grid item>
                <MDTypography component="span" variant="body2" fontWeight="bold">
                   امتیاز کلی : &nbsp;
                  </MDTypography>
                  <MDTypography component="span" variant="body2" fontWeight="bold" dir="rtl">
                    <span>{rating}&nbsp;</span>
                    {[...Array(5)].map((_, index) => (
                        <StarIcon key={index} color={index < (5 - rating) ? 'action' : 'primary'} />
                    ))}
                  </MDTypography>
                </Grid>
              </Grid>
              <MDTypography fontWeight="light" color="text" dangerouslySetInnerHTML={{ __html: description }} 
                sx={{
                  fontSize: {
                    xs: '0.8rem', // Small screens
                    sm: '1rem',   // Large screens
                    lg: '1.2rem',  // Extra-large screens
                  },
                  lineHeight: {
                    xs: 1.5,
                    sm: 1.6,
                    md: 1.7,
                    lg: 1.8,
                  },
                }}
              >
             
              </MDTypography>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </MDBox>
  );
}

export default Profile;
