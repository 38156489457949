/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";
import {Skeleton, TextField} from "@mui/material";
import FormControl from '@mui/material/FormControl';
import Paper from '@mui/material/Paper';

// Material Kit 2 React components
import MDBox from "components/MDBox";


// Images
import React, {useEffect, useState} from "react";
import MDTypography from "../../../components/MDTypography";
import MDButton from "../../../components/MDButton";
import {styled} from "@mui/material/styles";
import ClientOrderService from "../../../services/client-order-service";
import OrderService from "../../../services/order-service";
import {useParams} from "react-router-dom";
import Card from "@mui/material/Card";
import DashboardLayout from "../../../examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../../../examples/Navbars/DashboardNavbar";
import MDmapChooser from "../../../components/MDmapChooser";
import Stack from "@mui/material/Stack";
import {numberFormat} from "../../../services/helperFunctions";
import MDInput from "../../../components/MDInput";
import Swal from "sweetalert2";
import orderService from "../../../services/order-service";


const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
});

function OrderAddressHandler() {
    const { id } = useParams(); // Destructure the id from the returned object
    const [parentLocation, setParentLocation] = useState({lat : null, lng : null});
    const [salerLocation, setSalerLocation] = useState({lat : null, lng : null});
    const [address, setAddress] = useState("آدرس شما");
    const [oldAddress, setOldAddress] = useState(null);
    const [order, setOrder] = useState({});
    const [prices, setPrices] = useState({
        price: false, // Total price
        orderItems: [
            { price: 0 } 
        ],
        shippingPrices: {
            back: 0,
            forth: 0,
        }
    });
    const [button, setButton] = useState(null);

    const [clientAddresses, setClientAddresses] = useState([]);

    const changeHandler = (e) => {
        setOldAddress(e.target.value)
    };

    const fetchClientAddresses = async () => {
        const userData = localStorage.getItem('token'); // Use getItem, not key
        const response = await ClientOrderService.clientAddresses(JSON.stringify(userData));

        const addresses = response.data.map(item => ({
            id: item.id,
            title: item.title,
            address: item.address
        }));

        setClientAddresses(addresses);

    };
    
    const fetchOrder = async (orderId) => {
        const userData = localStorage.getItem('token'); // Use getItem, not key
        const response = await orderService.order([], orderId);
        setOrder(response.data)
        

    };
    useEffect(() => {

        
            fetchOrder(id)

        // fetchClientAddresses();

    }, []);

    useEffect(() => {
        if(order.saler){
            setSalerLocation({
            lat : order.saler.location.lat,
            lng : order.saler.location.lng           
        })
        }
        
     
    }, [order]);



    async function getReverseGeocodingData(lat, lng) {
        const url = `https://nominatim.openstreetmap.org/reverse?format=json&lat=${lat}&lon=${lng}&language=fa`;

        try {
            const response = await fetch(url);
            const data = await response.json();

            // Extract the street name or other specific details from the address
            const address = data.address;
            // Fallback to neighbourhood if road is not available
            return `${address.neighbourhood ? address.neighbourhood + ' ' : ''}${address.road || ''}`;
        } catch (error) {
            console.error('Error:', error);
            return 'Error occurred';
        }
    }

    useEffect(() => {        
        if (parentLocation.lat){
            getReverseGeocodingData(parentLocation.lat, parentLocation.lng).then(street =>
                setAddress(street)
            );
        }

    }, [parentLocation]);

    const assignAddressToOrder = async () => {
        let data = {}
        if(parentLocation.lat == null){
            Swal.fire({
                title : 'موقعیت خود را روی نقشه وارد کنید',
                icon : 'error',
                confirmButtonText : 'باشه',
                text : 'سفارش شما به آدرس انتخاب شده ارسال خواهد شد.',
                timer : 3000
            })
        }else{
            data.cientAddressId = oldAddress
            data.location = parentLocation
            data.address = address
    
            const response = await ClientOrderService.assignAddressToOrder(data, id);

            
            if (response.status == 'success'){
                setPrices(prevPrices => ({
                    ...prevPrices, // Keep the previous state properties
                    price: true,
                    orderItems: response.data.orderItems.map(item => ({
                        price: item.saler_package.price.price * item.count,
                    })),
                    shippingPrices: {
                        back: response.data.shippingPrices[0],
                        forth: response.data.shippingPrices[1],
                    },
                }));
                
                
            }
        }
      
    };
    const submitHandler = async () => {

        setButton('در حال انتقال به درگاه')
        let data = {}
        const response = await ClientOrderService.submitOrder(data, id);

        if (response.token){

            const token = response.token;

            if (token){
                const responseUrl = await OrderService.payment(token);
            
                if (responseUrl.paymentUrl){
                    window.location.href = responseUrl.paymentUrl;
                }
            }
        }

    };
    const handleLocationChange = (location) => {
        console.log(location);
        setPrices({
            price: false, // Total price
            orderItems: [
                { price: 0 } 
            ],
            shippingPrices: {
                back: 0,
                forth: 0,
            }
        })
        setParentLocation(location);
    }

    const Item = styled(Paper)(({ theme }) => ({
        backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
        ...theme.typography.body2,
        padding: theme.spacing(1),
        textAlign: 'center',
        color: theme.palette.text.secondary,
        flexGrow: 1,
        display: 'flex',           // Enable flexbox
        alignItems: 'center',     // Center vertically
        justifyContent: 'center',  // Center horizontally (optional)
        height: '100%',           // Ensure it takes full height of the parent
    }));
    
  return (
      <DashboardLayout>
          <DashboardNavbar/>
          <Card>
              <MDBox
                  mx={2}
                  mt={-2}
                  py={2}
                  px={2}
                  variant="gradient"
                  bgColor="secondary"
                  borderRadius="lg"
                  coloredShadow="secondary"
              >
                  <MDTypography variant="h6" color="white">
                      آدرس شما
                  </MDTypography>
              </MDBox>
              <Grid container xs={12} spacing={3}  alignItems="center" sx={{ mx: "auto" }}>

                  {/*<Grid item xs={12} px={5} py={1} mt={5}>*/}
                  {/*    <MDBox*/}
                  {/*        display="flex"*/}
                  {/*        flexDirection="column"*/}
                  {/*        alignItems="flex-start"*/}
                  {/*        width="100%"*/}
                  {/*    >*/}
                  {/*            <FormControl fullWidth>*/}

                  {/*                <InputLabel id="demo-simple-select-label">ادرس های قبلی</InputLabel>*/}
                  {/*                <Select*/}
                  {/*                    labelId="demo-simple-select-label"*/}
                  {/*                    id="demo-simple-select-standard"*/}
                  {/*                    label="ادرس های قبلی"*/}
                  {/*                    name="address"*/}
                  {/*                    value={1}*/}
                  {/*                    variant="outlined"*/}
                  {/*                    onChange={changeHandler}*/}
                  {/*                >*/}
                  {/*                    {clientAddresses && clientAddresses.length > 0 && clientAddresses.map((item) => (*/}
                  {/*                        <MenuItem key={item.id} value={item.id}>{item.title+' ('+item.address+')'}</MenuItem>*/}
                  {/*                    ))}*/}
                  {/*                </Select>*/}

                  {/*            </FormControl>*/}
                  {/*    </MDBox>*/}
                  {/*</Grid>*/}
                  <Grid item xs={12} px={5} mt={5}>
                            <MDBox
                                display="flex"
                                flexDirection="column"
                                alignItems="flex-start"
                                width="100%">
                                <MDTypography variant="caption" color="text" mb={1} fontWeight="bold">
                                    آدرس دفتر فنی :
                                </MDTypography>
                                {order.id ? (
                                            <MDTypography variant="body2" color="error" mb={1} fontWeight="regular">
                                                {order.saler.address}  
                                            </MDTypography>
                                        ) : (
                                    <Skeleton sx={{ height: 45 }} animation="wave" variant="rectangular" />
                                )}
                            </MDBox>
                  
                  </Grid>
                  
                  <Grid item xs={12} px={5} >
                      <MDBox
                          display="flex"
                          flexDirection="column"
                          alignItems="flex-start"
                          width="100%">
                          <FormControl fullWidth>
                              <MDInput
                                  name="address" id="outlined-basic" value={address} label="ادرس" variant="outlined" disabled/>
                              {/*<MDTypography variant="body" color="text" mt={0.5} fontWeight="regular">*/}
                              {/*    ( توضیحات آدرس خود را در قسمت بالا وارد کنید)*/}
                              {/*</MDTypography>*/}
                          </FormControl>
                      </MDBox>
                  </Grid>
                  <Grid item xs={12} px={5} py={1}>
                      <MDTypography variant="body2" color="text" fontWeight="bold">
                          نقشه
                      </MDTypography>

                      <MDTypography variant="caption" color="text" mb={1} fontWeight="light">
                          موقعیت مکانی خود روی نقشه را انتخاب کنید
                      </MDTypography>
                      <MDBox width="100%">
                        {salerLocation.lat ? (
                          <MDmapChooser location={salerLocation} onLocationChange={handleLocationChange}/>) :
                          (

                            <Skeleton sx={{ height: 400 }} animation="wave" variant="rectangular" />
                          )
                          }
                      </MDBox>

                      <MDTypography variant="caption" color="error" mb={1} fontWeight="light">
                          نشانگر قرمز موقعیت مکانی دفتر فنی را نشان میدهد
                      </MDTypography>
                  </Grid>
                  {prices.price &&
                      <Grid item xs={12} px={5} py={1}>
                          <Stack spacing={{ xs: 1, sm: 2 }} direction="row" useFlexGap flexWrap="wrap">

                          <Item>

                          <Stack spacing={{ xs: 1, sm: 2 }}>
                            {prices.orderItems.map((item) => (
                                <Item>
                                <span>{'آیتم سفارش'}: {numberFormat(parseInt(item.price))} تومان</span>
                                </Item>                
                            ))}
                            </Stack>
                           </Item>
                                
                              <Item>
                                {prices.shippingPrices.forth ? (
                                    <Stack spacing={{ xs: 1, sm: 2 }} direction="row" useFlexGap flexWrap="wrap">
                                        <Item>
                                            پیک برگشت  :   {numberFormat(prices.shippingPrices.back)}  تومان
                                        </Item>
                                        <Item>
                                            پیک رفت :   {numberFormat(prices.shippingPrices.forth)}  تومان
                                        </Item>
                                    </Stack>) : (
                                        <Item>
                                            ارسال :   {numberFormat(prices.shippingPrices.back)}  تومان
                                        </Item>
                                    )}
                              </Item>
                              <Item alignItems='center'>
                                جمع : {numberFormat(
                                    prices.orderItems.reduce((total, item) => 
                                        total + (parseInt(item.price) || 0), 0) + 
                                    (parseInt(prices.shippingPrices.back) || 0) + 
                                    (parseInt(prices.shippingPrices.forth) || 0)
                                )} تومان
                            </Item>



                          </Stack>
                      </Grid>
                  }
                  <Grid item xs={12} px={5} py={2}>
                      <MDBox>

                              {prices.price  ?  ( <MDButton
                                  disabled={button}
                                  fullWidth component="label"
                                                            width="100%"
                                                            color="info"
                                                            onClick={submitHandler}
                              >
                                  {button ? 'درحال انتقال به درگاه پردخت' :  'پرداخت'}
                              </MDButton>) : ( <MDButton fullWidth component="label"
                                                         width="100%"
                                                         color="info"
                                                         onClick={assignAddressToOrder}
                                                         loading
                              >
                                  ثبت
                              </MDButton>)
                              }

                      </MDBox>
                  </Grid>
              </Grid>
          </Card>
      </DashboardLayout>

  );
}

export default OrderAddressHandler;
