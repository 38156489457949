/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect } from "react";

// prop-types is a library for type checking of props
import PropTypes from "prop-types";

// react-copy-to-clipboard components

// react-syntax-highlighter components

// @mui material components
import Grid from "@mui/material/Grid";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Slide from "@mui/material/Slide";

// Material Kit 2 React components
import MDBox from "components/MDBox";
import MDAlert from "components/MDAlert";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";

// Material Kit 2 React base styles
import colors from "assets/theme/base/colors";

function View({ children, code, title, height, ...rest }) {
  const { grey } = colors;

  return (
    <MDBox
      width="100%"
      position="relative"
      shadow="lg"
      // mb={12}
      sx={{ overflow: "hidden" }}
      {...rest}
    >
        <MDBox
            height='100vh' width="100%">
            <MDBox
                bgColor="grey-100"
                width="105%"
                height='100vh'
                sx={{ overflowX: "hidden", overflowY: "scroll" }}
            >
                {children}
            </MDBox>
        </MDBox>
    </MDBox>
  );
}

// Setting default props for the View
View.defaultProps = {
  height: "auto",
};

// Typechecking props for the View
View.propTypes = {
  children: PropTypes.node.isRequired,
  code: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  height: PropTypes.string,
};

export default View;
