import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { Button, CardActionArea, CardActions, Chip } from '@mui/material';
import Grid from "@mui/material/Grid";
import StarIcon from '@mui/icons-material/Star';
import { Link } from 'react-router-dom';
import MDBox from 'components/MDBox';
export default function MDCardCustom({title, badge, description, address,image,alt, buttonTitle, route, rating}) {


console.log(badge);

    return (
        <Link to={route}>
            <Card width="100%" sx={{ position: 'relative', overflow: 'visible' }}>
                <CardActionArea>
                    <Grid container spacing={1} alignItems="center">
                        <Grid item xs={3} sm={1} sx={{ display: 'flex', justifyContent: 'center', ml: { xs: 0, sm: 6 }, backgroundColor: 'white' }}>
                            <CardMedia
                                component="img"
                                height="100"
                                image={image}
                                alt={alt}
                                sx={{
                                    position: 'absolute',
                                    top: -40,
                                    width: 100,
                                    height: 100,
                                    borderRadius: '50%',
                                    objectFit: 'contain',
                                    boxShadow: 3
                                }}
                            />
                        </Grid>
                        <Grid item xs={9} sm={10} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                            <CardContent>
                               
                                <MDBox display="flex" p={1}>
                                <Typography gutterBottom variant="h5" component="div">
                                    {title}
                                </Typography>
                                {badge == false && (
                                        <MDBox mx={1}>
                                        <Chip color="error" size="small" variant="outlined" label='بسته است'/>
                                        </MDBox>
                                        )}
                                </MDBox>
                               
                                {Number.isFinite(rating)  ? (
                                    <Typography variant="caption" color="text.primary" sx={{ display: 'flex', alignItems: 'center' }}>
                                        <span>{rating}</span>
                                        {[...Array(5)].map((_, index) => (
                                            <StarIcon key={index} color={index < rating ? 'primary' : 'action'} />
                                        ))}
                                    </Typography>
                                    ) : (
                                        <Typography variant="caption" color="error" sx={{ display: 'flex', alignItems: 'center' }}>
                                        <span>{rating}</span>
                                        </Typography>
                                    )}
                                
                                <Typography variant="body2" color="text.secondary">
                                {description}
                                </Typography>
                                {address ? (
                                    <Typography variant="caption" color="text.secondary">
                                        {` آدرس : ${address}`}
                                    </Typography>
                                ) : ''}
                            </CardContent>
                            <CardActions sx={{ justifyContent: 'flex-start' }}>        
                                    <Button size="small" color="primary">
                                        {buttonTitle}
                                    </Button>
                            </CardActions>
                        </Grid>
                    </Grid>
                </CardActionArea>
            </Card>
        </Link>

    );
}
