/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";

// Data
import {useCallback, useEffect, useState} from "react";
import {Link, useParams} from 'react-router-dom';
import OrderService from "../../services/client-order-service";
import DashboardNavbar from "../../examples/Navbars/DashboardNavbar";
import Icon from "@mui/material/Icon";
import MDTypography from "../../components/MDTypography";
import Divider from "@mui/material/Divider";
import MDButton from "../../components/MDButton";
import Bill from "../order/Bill";
import CustomStepper from "../order/customStepper";
import DeliveryDiningOutlined from "@mui/icons-material/DeliveryDiningOutlined";
import SnappService from "../../services/snapp-service";
import {FormControl, InputLabel, Rating, Step, StepLabel, Stepper, TextField} from "@mui/material";
import MopedIcon from "@mui/icons-material/Moped";
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import MuiLink from "@mui/material/Link";
import {SupportTelegramLink} from "../../services/helperFunctions";
import { Comment } from "@mui/icons-material";
import orderService from "services/order-service";
import Swal from "sweetalert2";
import { toBeEmpty } from "@testing-library/jest-dom/dist/matchers";

function OrderItem(props) {
    if (!Array.isArray(props.items)) {
        return null; // or return some fallback JSX if you prefer
    }

    return (
        <ul>
            {props.items.map((item, index) => (
                <MDBox pb={2} px={2}>
                    <MDTypography component="p" variant="button" color="text" display="flex">
                        <MDTypography
                            component="span"
                            variant="button"
                            fontWeight="bold"
                            color="dark"
                        >
                            {item.package && item.package.title}
                        </MDTypography>
                        &#215;{item.count}
                    </MDTypography>
                </MDBox>

            ))}
        </ul>
    );
}

function Order() {
    console.log('arrrr')
    const { id } = useParams();
    const [body, setBody] = useState('');
    const [rate, setRate] = useState(null);
    const [order, setOrder] = useState([]);
    const [doneSteps, setDoneSteps] = useState([]);
    const [comment, setComment] = useState(null);


    useEffect(() => {

        // Define an async function within the useEffect
        const fetchData = async () => {
            const userData = localStorage.getItem('token'); // Use getItem, not key
            const response = await OrderService.order(JSON.stringify(userData), id);
            setOrder(response.data)
            setDoneSteps(response.doneSteps)
            setComment(response.data.comments[0])
            
        };

        // Call the async function
        fetchData();

    }, []);

    useEffect(() => {
     
    }, [body, rate]);
    const submitCommentHandler = async () => {
        console.log('arrr');
       
        let commentData = {}
        commentData['order_id'] = id
        commentData['body'] = body
        commentData['rate'] = rate
        const response = await orderService.submitComment(commentData);

        console.log(response.status);
        
        if (response.status == 'success'){
            setBody('')
            setRate(null)
            
         
            Swal.fire({
                title: "نظر شما ثبت شد",
                text: "ممنون که نظر خود را ثبت کردید ,نظر شما به سفارش بهتر در این سامانه کمک خواهد کرد",
                icon: "success",
              })
        }
    };
    



    return (
    <DashboardLayout>
        <DashboardNavbar />
        <MDBox py={3}>
            <Grid item xs={12} md={6} lg={3}>
                <Card>
                    <MDBox display="flex" justifyContent="space-between" pt={1} px={2}>

                        <MDBox display="flex">
                            <MDBox
                                variant="gradient"
                                bgColor='dark'
                                color='light'
                                coloredShadow='light'
                                borderRadius="xl"
                                display="flex"
                                justifyContent="center"
                                alignItems="center"
                                width="4rem"
                                height="4rem"
                                mt={-3}
                            >
                                <Icon fontSize="large" color="inherit">
                                    print
                                </Icon>
                            </MDBox>
                            <MDBox textAlign="right" px={2} py={1}>
                                <MDTypography variant="h6" fontWeight="medium">
                                    اطلاعات تکمیلی سفارش
                                </MDTypography>
                            </MDBox>
                        </MDBox>



                        <MDBox textAlign="right" lineHeight={1.25}>
                            <MDTypography variant="button" fontWeight="light" color="text">
                                تاریخ ایجاد سفارش :
                            </MDTypography>
                            <MDTypography variant="button" fontWeight="light" color="text">
                                { order.creation_date}
                            </MDTypography>
                            <MDTypography pt={1} variant="h4">
                                <MDButton
                                    width='100%'
                                    component={MuiLink}
                                    href={SupportTelegramLink}
                                    target="_blank"
                                    rel="noreferrer"
                                    size="small"
                                    endIcon={<SupportAgentIcon fontSize="large" />}
                                    color="warning"
                                >
                                    تماس با پشتیبانی
                                </MDButton>
                            </MDTypography>
                        </MDBox>
                    </MDBox>
                    <Divider />

                    <Grid container spacing={1} p={2}>
                        <Grid item xs={12}>
                            <CustomStepper doneSteps={doneSteps}/>
                        </Grid>

                        {(doneSteps == 4 && comment == null ) && (<Grid item xs={12}>
                        <MDBox
                            sx={{
                                padding: 2,
                                border: '1px solid #ccc',
                                borderRadius: 2,
                                boxShadow: 2,
                            }}
                            >
                            <MDTypography variant="h6" gutterBottom>
                            ثبت دیدگاه و امتیاز
                            </MDTypography>
                            <MDBox 
                                mt={1}>
                            <TextField
                                label="عنوان نظر"
                                variant="outlined"
                                fullWidth
                                multiline
                                rows={4}
                                value={body}
                                onChange={(e) => setBody(e.target.value)}
                            />
                            </MDBox>
                            <Rating
                                name="simple-controlled"
                                value={rate}
                                onChange={(event, newValue) => {
                                setRate(newValue);
                                }}
                                sx={{ marginY: 2 }}
                            />
                            <MDButton
                                variant="contained"
                                color="info"
                                onClick={submitCommentHandler}
                                loading
                                endIcon={<Comment fontSize="large" />}
                            >
                                ثبت 
                            </MDButton>
                            </MDBox>
                        </Grid>)}

                        

                        {comment && (
                          <Grid item xs={12}>
                                <MDBox display='block'> 
                                    <MDTypography variant="caption" color='secondary' fontWeight='light'>
                                      دیدگاه  شما درباره این سفارش : 
                                    </MDTypography>
                                </MDBox>

                                <Card>
                                    <MDBox p={3} >
                                        <MDBox> 
                                            <MDTypography variant="button" color='secondary' fontWeight='light'>
                                            {comment.body}
                                            </MDTypography>
                                        </MDBox>

                                        <MDBox> 
                                            <Rating
                                            disabled
                                            name="simple-controlled"
                                            value={comment.rate}
                                            />
                                        </MDBox>
                                    </MDBox>
                                </Card>
                             
                        </Grid>
                        )}
                      
                        
                        <Grid item xs={12}>
                            <MDBox>
                                <MDTypography variant="button" color='secondary' fontWeight='light'>
                                    مشکلی در سفارش خود دارید ؟
                                </MDTypography>
                            </MDBox>
                            <MDBox>
                                <MDTypography variant="button" color='secondary' fontWeight='light'>
                                    سوالات متداول
                                </MDTypography>
                            </MDBox>
                        </Grid>
                    </Grid>
                </Card>
            </Grid>
        </MDBox>
    </DashboardLayout>
  );
}

export default Order;
