/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DataTable from "examples/Tables/DataTable";


import SweetAlert2 from 'react-sweetalert2';

// Data
import orderTableData from "layouts/orders/data/orderTableData";
import {useEffect, useState} from "react";
import OrderService from "../../services/client-order-service";
import MDButton from "../../components/MDButton";
import {Link, useSearchParams} from "react-router-dom";
import OrdersMobileView from "./data/ordersMobileView";


function Orders() {
    const [tableData, setTableData] = useState({ columns: [], rows: [] });
    const [status, setStatus] = useSearchParams();
    const [swalProps, setSwalProps] = useState({});
    const [orders, setOrders] = useState([]);

    const fetchOrders = async () => {
      const userData = localStorage.getItem('token'); // Use getItem, not key
      const response = await OrderService.orders(JSON.stringify(userData));

      const { columns, rows } = orderTableData(response.data);
      setOrders(response.data)
      setTableData({ columns, rows });
  };

    useEffect(() => {

      fetchOrders();


    }, []);


    useEffect(() => {

        let orderStatus = status.get('paystatus')
        if (orderStatus === 'failed'){
            console.log('failed')
            setSwalProps({
                show: true,
                title: 'پرداخت نشد',
                text: 'مشکلی در پرداخت پیش آمده لطفا مجددا تلاش کنید',
            });
        }
        else if(orderStatus == 'success'){

            console.log('success')
            setSwalProps({
                show: true,
                title: 'پرداخت شد',
                text: 'سفارش شما با موفقیت پرداخت شد',
            });
        }
    }, [status]);
  const { columns, rows } = tableData;
    // const destroyHandler = async (id) => {
    //     // const response = await OrderService.deletOrder(id);
    //     console.log('dasdasdasd')
    // };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={2} pb={3}>
        <Grid container  direction="row-reverse" spacing={6}>
            <SweetAlert2 {...swalProps} />
          <Grid item xs={12} md={6} lg={6}>
                <Card p={2}>
                    <MDBox py={2} px={3}>
                        <MDButton
                            component={Link}
                            to={"/create-order"}
                            href={(e) => e.preventDefault()}
                            target=""
                            fullWidth
                            rel={"noreferrer"}
                            color="info"
                        >ایجاد سفارش جدید</MDButton>
                    </MDBox>
                </Card>
            </Grid>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  جدول سفارشات
                </MDTypography>
              </MDBox>
              <MDBox pt={3} sx={{
                    display: {
                        xs: 'none', // Hide on extra small screens
                        lg: 'block', // Show on small screens and up
                    },
                }}>
                <DataTable

                  table={{ columns, rows }}
                  isSorted={false}
                  entriesPerPage={false}
                  showTotalEntries={false}
                  noEndBorder
                />
              </MDBox>

              <MDBox pt={1} sx={{
                    display: {
                        xs: 'block', // Hide on extra small screens
                        lg: 'none', // Show on small screens and up
                    },
                }}>
                <OrdersMobileView orders={orders}/>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}

export default Orders;
