import React, { useRef, useState, useEffect } from 'react';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import markerIcon from 'leaflet/dist/images/marker-icon.png';
import markerIcon2x from 'leaflet/dist/images/marker-icon-2x.png';
import markerShadow from 'leaflet/dist/images/marker-shadow.png';
import redLocation from 'assets/images/redlocation.png'

delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
    iconRetinaUrl: markerIcon2x,
    iconUrl: markerIcon,
    shadowUrl: markerShadow,
});
function MDmapChooser({ onLocationChange , location}) {
    const [selectedLocation, setSelectedLocation] = useState({ lat: null, lng: null });
    const [defaultMarker, setDefaultMarker] = useState(null);
    const [marker, setMarker] = useState(null);
    const mapContainer = useRef(null);
    const mapInstance = useRef(null); 
    

    const customIcon = L.icon({
        iconUrl: redLocation,
        iconRetinaUrl: redLocation,
        iconSize: [40, 40], // Adjust size as needed
        iconAnchor: [12, 41], // Anchor point of the icon
        popupAnchor: [1, -34], // Popup anchor point
    });
    const onMapClick = (e) => {
            
        setSelectedLocation({
            lat: e.latlng.lat,
            lng: e.latlng.lng
        })

        ;


        // Remove the previous marker if it exists
        if (marker) {
            mapInstance.current.removeLayer(marker);
            setMarker(null);
        }

        // // Place a new marker at the clicked location and save it in the marker state
        const newMarker = L.marker([e.latlng.lat, e.latlng.lng]).addTo(mapInstance.current);
        setMarker(newMarker);
    };
    
    useEffect(() => {
 
        
        // Check if map is not already initialized
        if (!mapInstance.current) {
             
            // mapInstance.current = L.map(mapContainer.current).setView([32.6539, 51.6660], 14);
            mapInstance.current = L.map(mapContainer.current).setView([location.lat - 0.001 , location.lng - 0.001], 14);


            // L.tileLayer('https://{s}.basemaps.cartocdn.com/light_nolabels/{z}/{x}/{y}{r}.png', {
            //     maxZoom: 19,
            // }).addTo(mapInstance.current);

            L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
                maxZoom: 19,
            }).addTo(mapInstance.current);
         
        }
   mapInstance.current.on('click', onMapClick);

            onLocationChange(selectedLocation)
            return () => {
                // Cleanup the click event when the component unmounts
                mapInstance.current.off('click', onMapClick);
            };
    }, [marker]);
    //
    useEffect(() => {
        if (location.lat && location.lng){
            const defaultMarker = L.marker([location.lat, location.lng], { icon: customIcon }).addTo(mapInstance.current);
            setDefaultMarker(defaultMarker);
        }

    }, [location]);


    return (
        <div>
            <div ref={mapContainer} style={{ height: '400px' , borderRadius: '25px'}} ></div>
        </div>
    );
}

export default MDmapChooser;
