import Axios from "axios";
import Swal from "sweetalert2";

const API_URL = 'https://easychapp.com/api/';
// const API_URL = 'http://localhost:8000';
Axios.defaults.baseURL = API_URL;

export class HttpService {
  _axios = Axios.create();

  addRequestInterceptor = (onFulfilled, onRejected) => {
    this._axios.interceptors.request.use(onFulfilled, onRejected);
  };

  addResponseInterceptor = (onFulfilled, onRejected) => {
    this._axios.interceptors.response.use(onFulfilled, onRejected);
  };

  get = async (url) => await this.request(this.getOptionsConfig("get", url));

  post = async (url, data) => await this.request(this.getOptionsConfig("post", url, data));

  put = async (url, data) => await this.request(this.getOptionsConfig("put", url, data));

  patch = async (url, data) => await this.request(this.getOptionsConfig("patch", url, data));

  delete = async (url) => await this.request(this.getOptionsConfig("delete", url));

  getOptionsConfig = (method, url, data) => {
    return { method, url, data, headers: { 'Content-Type': 'application/json', 'Accept' : 'application/json' } };
  };

  request(options) {
    return new Promise((resolve, reject) => {
      this._axios
        .request(options)
        .then((res) => resolve(res.data))
        .catch((ex) => {
          Swal.fire({
            icon: 'error',
            title: 'خطا',
            text: ex.response.data.message ||  ex.response.data.message[0] || 'مشکلی پیش آمده!',
          });
          reject(ex.response.data)
        });
    });

  }
}

export default new HttpService();
