import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDBadge from "components/MDBadge";

// Images
import Grid from "@mui/material/Grid";
import MDButton from "../../../components/MDButton";
import {Link} from "react-router-dom";
import MopedIcon from '@mui/icons-material/Moped';
import AddCommentIcon from '@mui/icons-material/AddComment';
import CancelIcon from '@mui/icons-material/Cancel';
import { numberFormat } from "services/helperFunctions";
import { Container } from "@mui/material";

function OrdersMobileView({orders}) {
    
    const PackageType = ({ category ,sellerPackage, copyCount, price }) => (
        <MDBox lineHeight={1} textAlign="left"  
            p={2}
            mt={1}
            borderRadius='lg'
            sx={{
                backgroundColor : 'grey.200'
            }}
         >
            <MDTypography display="block" variant="caption" color="text" fontWeight="medium">
                {'دسته بندی : '} {category}
            </MDTypography>
            <MDTypography display="block" variant="caption" color="text" fontWeight="medium">
                {' پکیج : '} {sellerPackage} 
            </MDTypography>
            <MDTypography display="block" variant="caption" color="text" fontWeight="medium">
                {'تعداد : '}{copyCount}
            </MDTypography>
            <MDTypography display="block" variant="caption" color="text" fontWeight="medium">
                {'قیمت : '}{price}
            </MDTypography>
        </MDBox>
    );

    const orderItemHandler = (orderItems) => {
        let count = orderItems.length
        return orderItems.map(item => (
            <PackageType category={item.saler_package.package.category.title} price={item.saler_package.price.price}  sellerPackage={item.saler_package.title} copyCount={item.count && item.count.toString()} />
        ));
    };

    const status = (order) => {
        switch (order.status){
            case 'تحویل داده شد' :
                return (<MDBox ml={-1}>
                    <MDBadge badgeContent={order.status}
                             color='success'
                             variant="gradient" size="lg"/>
                </MDBox>)
            break
            case 'در راه' :
                return (<MDBox ml={-1}>
                    <MDBadge badgeContent={order.status}
                             color={order.status === "info"}

                             variant="gradient" size="lg"/>
                </MDBox>)
            break
            case 'در حال انجام' :
                return (<MDBox ml={-1}>
                    <MDBadge badgeContent={order.status}
                             color='info'
                             variant="gradient" size="lg"/>
                </MDBox>)
            break
            case 'منتظر پرداخت' :
                return (<MDBox ml={-1}>
                    <MDBadge badgeContent={order.status}
                             color='warning'

                             variant="gradient" size="lg"/>
                </MDBox>)
            break
            case 'لغو شد' :
                return (<MDBox ml={-1}>
                    <MDBadge badgeContent={order.status}
                             color='error'

                             variant="gradient" size="lg"/>
                </MDBox>)
            break
            case 'سفارش نا تمام' :
                return (<MDBox ml={-1}>
                    <MDBadge badgeContent={order.status}
                             color='warning'
                             variant="gradient" size="lg"/>
                </MDBox>)
            break
        }

    }

    const action = (order) => {
        if (order.status == 'تحویل داده شد'){
            return ( <MDButton
                component={Link}
                to={"/order/"+order.id}
                href={(e) => e.preventDefault()}
                target=""
                rel="noreferrer"
                size="small"
                variant="outlined"
                endIcon={<AddCommentIcon />}
                color="success"
                // disabled
            >
                ثبت نظر
            </MDButton>)
        }else if(order.status == 'در راه'){
            return (<MDButton
                component={Link}
                to={"/order/"+order.id}
                href={(e) => e.preventDefault()}
                target=""
                rel="noreferrer"
                size="small"
                variant="outlined"
                endIcon={<MopedIcon fontSize="large" />}
                color="info"
            >
                پیگیری
            </MDButton>)
        }else if(order.status == 'لغو شد'){
            return (<MDButton
                disabled
                size="small"
                variant="outlined"
                endIcon={<CancelIcon fontSize="large" />}
                color="error"
            >
                لغو شده
            </MDButton>)
        }else if(order.status == 'در حال انجام'){
            return (<MDButton
                component={Link}
                to={"/order/"+order.id}
                href={(e) => e.preventDefault()}
                target=""
                rel="noreferrer"
                size="small"
                variant="outlined"
                endIcon={<MopedIcon fontSize="large" />}
                color="info"
            >
                پیگیری
            </MDButton>)
        }else if(order.status == 'منتظر پرداخت') {
            return (<MDButton
                component={Link}
                to={"/create-order/step3/"+order.id}
                href={(e) => e.preventDefault()}
                target=""
                rel="noreferrer"
                size="small"
                variant="outlined"
                endIcon={<MopedIcon fontSize="large" />}
                color="warning"
            >
                پرداخت
            </MDButton>)
        }else if(order.status == 'سفارش نا تمام') { 
            return (<MDButton
                component={Link}
                to={"/create-order/step2/"+order.id}
                href={(e) => e.preventDefault()}
                target=""
                rel="noreferrer"
                size="small"
                variant="outlined"
                endIcon={<MopedIcon fontSize="large" />}
                color="warning"
            >
                تکمیل سفارش
            </MDButton>)
        }

    };
    
    const order = orders.map((order) => (
        <Grid item xs={12} key={order.id}>
            <Container>
            <MDBox 
                p={2} 
                borderRadius='lg'
                sx={{ borderBottom: 1,
                    borderColor: 'grey.300',
                    backgroundColor : 'grey.100'
                 }}
            >
                <MDTypography 
                    display="block" 
                    variant="body2" // Increased prominence for the saler name
                    color="textPrimary" 
                    fontWeight="bold"
                >
                    {order.saler ? order.saler.name : 'دفتر فنی'}
                </MDTypography>

                <MDTypography 
                    display="block" 
                    variant="body2" 
                    color="textSecondary" 
                    fontWeight="medium"
                    mt={1} // Add margin-top for spacing
                >
                    {' هزینه سفارش : '} {numberFormat(order.price)} {' تومان '}
                </MDTypography>
                
                <MDTypography 
                    display="block" 
                    variant="body2" 
                    color="textSecondary" 
                    fontWeight="medium"
                    mt={1} // Add margin-top for spacing
                >
                    {' هزینه ارسال : '} {numberFormat(order.shippingPrice)} {' تومان '}
                </MDTypography>
                
                <MDTypography
                    target="_blank"
                    component="a"
                    href={order.order_items.length > 0 ? order.order_items[0].file : ''}
                    variant="body2"
                    color="primary" // Changed to primary color for emphasis
                    fontWeight="medium"
                    mt={1} // Add margin-top for spacing
                >
                    {(order.order_items.length > 0 && order.order_items[0].file) ? ' دانلود فایل' : ''}
                </MDTypography>
                
                <MDBox mt={1}>
                    <MDTypography variant="body2" color="textSecondary" fontWeight="medium">
                        {' تاریخ : '} {order.creation_date}
                    </MDTypography>
                </MDBox>
                
                <MDBox mt={1}>
                    {orderItemHandler(order.order_items)}
                </MDBox>
                <MDBox display="flex" justifyContent="space-between" mt={1}>
                    {status(order)}
                    {action(order)}
                </MDBox>
            </MDBox>
            </Container>
        </Grid>

    ));

    return (
        <Grid container spacing={3}>
            {order}           
        </Grid>
    );

}
export default OrdersMobileView;
