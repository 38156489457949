import { useState, useEffect, useMemo, useContext } from "react";

// react-router components
import { Routes, Route, Navigate, useLocation, useNavigate } from "react-router-dom";

// @mui material components
import { ThemeProvider} from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React example components
import Sidenav from "examples/Sidenav";
import Configurator from "examples/Configurator";

import themeRTL from "assets/theme/theme-rtl";

import themeDarkRTL from "assets/theme-dark/theme-rtl";

// RTL plugins
import rtlPlugin from "stylis-plugin-rtl";
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";

// Material Dashboard 2 React routes
import routes from "routes";

// Material Dashboard 2 React contexts
import { useMaterialUIController, setMiniSidenav, setOpenConfigurator } from "context";

// Images
import brandWhite from "assets/images/logo-ct.png";
import brandDark from "assets/images/logo-ct-dark.png";

import { setupAxiosInterceptors } from "./services/interceptor";
import ProtectedRoute from "examples/ProtectedRoute";

import { AuthContext } from "context";

import '../src/assets/styles.css';

export default function App() {
  const authContext = useContext(AuthContext);


    const [controller, dispatch] = useMaterialUIController();
  const {
    miniSidenav,
    direction,
    layout,
    openConfigurator,
    sidenavColor,
    transparentSidenav,
    whiteSidenav,
    darkMode,
  } = controller;
  const [onMouseEnter, setOnMouseEnter] = useState(false);
  const [rtlCache, setRtlCache] = useState(null);
  const [sidNavShow, setSidNavShow] = useState(false);
  const { pathname } = useLocation();

  // Cache for the rtl
  useMemo(() => {
    const cacheRtl = createCache({
      key: "rtl",
      stylisPlugins: [rtlPlugin],
    });

    setRtlCache(cacheRtl);
  }, []);

  // Open sidenav when mouse enter on mini sidenav
  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatch, false);
      setOnMouseEnter(true);
    }
  };

  // Close sidenav when mouse leave mini sidenav
  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      setMiniSidenav(dispatch, true);
      setOnMouseEnter(false);
    }
  };


  // Change the openConfigurator state
  const handleConfiguratorOpen = () => setOpenConfigurator(dispatch, !openConfigurator);

  // if the token expired or other errors it logs out and goes to the login page
  const navigate = useNavigate();
  setupAxiosInterceptors(() => {
    authContext.logout();
    navigate("/auth/login");
  });

  const [isDemo, setIsDemo] = useState(false);

  // useEffect(() => {
  //     document.body.setAttribute("dir", 'rtl');
  // }, []);



    // Setting page scroll to 0 when changing the route
  useEffect(() => {

    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    if (pathname === '/home'){

        setSidNavShow(false)
    }else{

        setSidNavShow(true)
    }
  }, [pathname]);

  const getRoutes = (allRoutes) =>
    allRoutes.map((route) => {
      if (route.collapse) {
        return getRoutes(route.collapse);
      }

      if (route.route && route.type !== "auth") {
          if(route.key === "home"){
              return (
                  <Route
                      exact
                      path={route.route}
                      element= {route.component}
                      key={route.key}
                  />
              );
          }
          if(route.key === "sellersProfile"){
            return (
                <Route
                    exact
                    path={route.route}
                    element= {route.component}
                    key={route.key}
                />
            );
        }
      //   if(route.key === "createOrder"){
      //     return (
      //         <Route
      //             exact
      //             path={route.route}
      //             element= {route.component}
      //             key={route.key}
      //         />
      //     );
      // }
        return (
          <Route
            exact
            path={route.route}
            element={
              <ProtectedRoute isAuthenticated={authContext.isAuthenticated}>
                {route.component}
              </ProtectedRoute>
            }
            key={route.key}
          />
        );
      }
      if(route.route && route.type === "auth"){
            return (
                <Route
                    exact
                    path={route.route}
                    element= {route.component}
                    key={route.key}
                />
            );
        }
      return null;
    });

  const configsButton = (
    <MDBox
      display="flex"
      justifyContent="center"
      alignItems="center"
      width="3.25rem"
      height="3.25rem"
      bgColor="white"
      shadow="sm"
      borderRadius="50%"
      position="fixed"
      right="2rem"
      bottom="2rem"
      zIndex={99}
      color="dark"
      sx={{ cursor: "pointer" }}
      onClick={handleConfiguratorOpen}
    >
      <Icon fontSize="small" color="inherit">
        settings
      </Icon>
    </MDBox>
  );

  return (
    <>
      <CacheProvider value={rtlCache}>
                <ThemeProvider theme={darkMode ? themeDarkRTL : themeRTL} >
                    <CssBaseline />
                    {(layout === "dashboard" && sidNavShow) && (
                        <>
                            <Sidenav
                                color={sidenavColor}
                                brand={(transparentSidenav && !darkMode) || whiteSidenav ? brandDark : brandWhite}
                                brandName="ایزی چاپ"
                                routes={routes}
                                onMouseEnter={handleOnMouseEnter}
                                onMouseLeave={handleOnMouseLeave}
                            />
                            <Configurator />
                         {/*   {configsButton}*/}
                        </>
                    )}
                    {layout === "vr" && <Configurator />}
                    <Routes>
                        {/*<Route path="login" element={<Navigate to="/auth/login" />} />*/}
                        {/*<Route path="register" element={<Navigate to="/auth/register" />} />*/}
                        {/*<Route path="forgot-password" element={<Navigate to="/auth/forgot-password" />} />*/}
                        {getRoutes(routes)}
                        <Route path="*" element={<Navigate to="/home" />} />
                    </Routes>
                </ThemeProvider>
      </CacheProvider>
    </>
  );
}
