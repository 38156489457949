/**
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";

import InstagramIcon from "@mui/icons-material/Instagram";
// Material Kit 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import {Telegram} from "@mui/icons-material";

function CenteredFooter({ company, links, socials, light }) {
  const { href, name } = company;

  const year = new Date().getFullYear();

  const renderLinks = links.map((link) => (
    <MDTypography
      key={link.name}
      component={Link}
      href={link.href}
      variant="body2"
      color={light ? "white" : "secondary"}
      fontWeight="regular"
    >
      {link.name}
    </MDTypography>
  ));

  const renderSocials = socials.map((social) => (
    <MDTypography
      key={social.link}
      target='_blanck'
      component={Link}
      href={social.link}
      variant="body2"
      color={light ? "white" : "secondary"}
      fontWeight="regular"
    >
      {social.icon}
    </MDTypography>
  ));

  return (
    <MDBox component="footer" py={6}>
      <Grid container justifyContent="center">
        <Grid item xs={10} lg={8}>
          <Stack
            direction="row"
            flexWrap="wrap"
            justifyContent="center"
            spacing={{ xs: 2, lg: 3, xl: 6 }}
            mb={3}
          >
            {renderLinks}
          </Stack>
        </Grid>
        <Grid item xs={12} lg={8}>
          <Stack display="flex" direction="row" justifyContent="center" spacing={3} mt={1} mb={3}>
            {renderSocials}
          </Stack>
        </Grid>
        <Grid item xs={12} lg={12} sx={{ textAlign: "center" }}>
          <MDTypography variant="body2" color={light ? "white" : "secondary"}>
              حقوق این وبسایت متعلق به شرکت ایزی چاپ می باشد
          </MDTypography>
        </Grid>
        <Grid item lg={6} sx={{ textAlign: "center" }}>
            <a referrerPolicy='origin' target='_blank'
               href='https://trustseal.enamad.ir/?id=437231&Code=LocCMGxnlDcXbXFFT9CqTZAWtjz3XeRy'>
                <img
                    referrerPolicy='origin'
                    src='https://trustseal.enamad.ir/logo.aspx?id=437231&Code=LocCMGxnlDcXbXFFT9CqTZAWtjz3XeRy' alt=''
                    style={{cursor: "pointer"}} Code='LocCMGxnlDcXbXFFT9CqTZAWtjz3XeRy'/>
            </a>
        </Grid>

        <Grid item lg={6} sx={{ textAlign: "center" }}>
            <a referrerPolicy='origin' target='_blank'
               href='https://logo.samandehi.ir/Verify.aspx?id=371773&p=xlaojyoerfthjyoejyoexlao'>
                <img referrerpolicy='origin' 
                  id = 'rgvjjzpejxlzjzpejzpergvj'
                  style={{cursor: "pointer"}}
                 onclick = 'window.open("https://logo.samandehi.ir/Verify.aspx?id=371773&p=xlaojyoerfthjyoejyoexlao", "Popup","toolbar=no, scrollbars=no, location=no, statusbar=no, menubar=no, resizable=0, width=450, height=630, top=30")' 
                 alt = 'logo-samandehi' 
                 src = 'https://logo.samandehi.ir/logo.aspx?id=371773&p=qftiyndtnbpdyndtyndtqfti' />
            </a>
        </Grid>
      </Grid>
    </MDBox> 
  );
}

// Setting default values for the props of CenteredFooter
CenteredFooter.defaultProps = {
  company: { href: "https://easychapp.com", name: "Easy Chapp" },
  links: [
    { href: "https://www.easychapp.com/about-us", name: "درباره ما" },
  ],
  socials: [
    {
      icon: <InstagramIcon fontSize="small" />,
      link: "https://www.instagram.com/easychapp/",
    },
    {
      icon: <Telegram fontSize="small" />,
      link: "https://t.me/easychapp",
    },
  ],
  light: false,
};

// Typechecking props for the CenteredFooter
CenteredFooter.propTypes = {
  company: PropTypes.objectOf(PropTypes.string),
  links: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.object])),
  socials: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.object])),
  light: PropTypes.bool,
};

export default CenteredFooter;
