/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";
import { Document, Page } from 'react-pdf';

// @mui material components
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import {useRef} from "react";

// Material Dashboard 2 React context
import { useMaterialUIController } from "context";
import {Download} from "@mui/icons-material";
import PrintIcon from "@mui/icons-material/Print";
import DeliveryDiningOutlined from "@mui/icons-material/DeliveryDiningOutlined";
import PdfPrint from "layouts/order/Bill/components/pdfPrint";
import Link from "@mui/material/Link";

function Bill({ name, company, mobile, vat, noGutter, file }) {
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;



    return (
    <MDBox
      component="li"
      display="flex"
      justifyContent="space-between"
      alignItems="flex-start"
      bgColor={darkMode ? "transparent" : "grey-100"}
      borderRadius="lg"
      p={3}
      mb={noGutter ? 0 : 1}
      mt={2}
    >
      <MDBox width="100%" display="flex" flexDirection="column">
        <MDBox
          display="flex"
          justifyContent="space-between"
          alignItems={{ xs: "flex-start", sm: "center" }}
          flexDirection={{ xs: "column", sm: "row" }}
          mb={2}
        >
          <MDTypography variant="button" fontWeight="medium" textTransform="capitalize">
            {name}
          </MDTypography>
            { file && (
          <MDBox display="flex" alignItems="center" mt={{ xs: 2, sm: 0 }} ml={{ xs: -1.5, sm: 0 }}>
            <MDBox mr={1}>
              {/*<MDButton variant="text" color="error">*/}
              {/*  <Icon>download</Icon>&nbsp; دانلود فایل ضمیمه*/}
              {/*</MDButton>*/}
              {/*  {file && }*/}

                <MDButton variant="contained" color="warning" size="medium"
                          // href={"https://localhost/attachment_files/"+file}
                          href={"http://127.0.0.1:8000/attachment_files/text.docx"}
                            target="_blank"
                          // rel="noopener noreferrer"
                          endIcon={<Download />}>
                    دانلود فایل ضمیمه
                </MDButton>
            </MDBox>

              {/*//TODO print*/}
              {/*<Link target="_blank"*/}

              {/*    // href="/order/1/print"*/}
              {/*    href="http://127.0.0.1:3000/attachment_files/text.docx"*/}
              {/*>*/}
              {/*    <MDButton  variant="contained" color="primary" size="medium" endIcon={<PrintIcon />}>*/}
              {/*        پرینت فایل ضمیمه*/}
              {/*    </MDButton>*/}
              {/*</Link>*/}
          </MDBox>
            )}
        </MDBox>
        <MDBox mb={1} lineHeight={0}>
          <MDTypography variant="caption" color="text">
            Company Name:&nbsp;&nbsp;&nbsp;
            <MDTypography variant="caption" fontWeight="medium" textTransform="capitalize">
              {company}
            </MDTypography>
          </MDTypography>
        </MDBox>
        <MDBox mb={1} lineHeight={0}>
          <MDTypography variant="caption" color="text">
            Phone number:&nbsp;&nbsp;&nbsp;
            <MDTypography variant="caption" fontWeight="medium">
              {mobile}
            </MDTypography>
          </MDTypography>
        </MDBox>
        <MDTypography variant="caption" color="text">
          VAT Number:&nbsp;&nbsp;&nbsp;
          <MDTypography variant="caption" fontWeight="medium">
            {vat}
          </MDTypography>
        </MDTypography>
      </MDBox>
    </MDBox>
  );
}

// Setting default values for the props of Bill
Bill.defaultProps = {
  noGutter: false,
};

// Typechecking props for the Bill
Bill.propTypes = {
  name: PropTypes.string.isRequired,
  company: PropTypes.string.isRequired,
  mobile: PropTypes.string.isRequired,
  vat: PropTypes.string.isRequired,
  noGutter: PropTypes.bool,
    file: PropTypes.string,
};

export default Bill;
