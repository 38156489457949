import {useState, useEffect} from "react";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import MDAlert from "components/MDAlert";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

// Overview page components
import Header from "layouts/user-profile/Header";

import AuthService from "../../services/client-auth-service";
import { Grid } from "@mui/material";
import Swal from "sweetalert2";

const UserProfile = () => {
    const [isDemo, setIsDemo] = useState(false);
    const [user, setUser] = useState({
        name: "",
        lastName: "",
        mobile: "",
        address: "",
        location: { lat: "", lng: "" },
        newPassword: "",
        confirmPassword: "",
    });

    const [errors, setErrors] = useState({
        nameError: false,
        mobileError: false,
        newPassError: false,
        confirmPassError: false,
    });

    const getUserData = async () => {
        const response = await AuthService.getProfile();
        setUser((prevUser) => ({
            ...prevUser,
            ...response.data
        }));

    };


    useEffect(() => {
        getUserData();

    }, []);


    const changeHandler = (e) => {
        setUser({
            ...user,
            [e.target.name]: e.target.value,
        });
    };

    const submitHandler = async (e) => {
        e.preventDefault();

        // validation
        if (user.name.trim().length === 0) {
            setErrors({...errors, nameError: true});
            return;
        }
        let userData = {
            name: user.name,
            lastName: user.lastName,
            mobile: user.mobile,
        };

        // call api for update
        const response = await AuthService.updateProfile(JSON.stringify(userData));
        if (response.error){
            Swal.fire({
                title: "خطا",
                text: response.message,
                icon: "error"
            });
            
        }else{
            // reset errors
            Swal.fire({
                title: "بروزرسانی با موفقیت انجام شد",
                text: response.message,
                icon: "success"
            });
        }



    };

    // const handleLocationChange = (location) => {
    //     setParentLocation(location);
    // }

    return (

        <DashboardLayout>
            <DashboardNavbar/>
            <MDBox mb={2}/>
            <Header name={user.name + ' ' + user.lastName} roll='کاربر'>

                 <MDBox
                    component="form"
                    role="form"
                    onSubmit={submitHandler}
                >
                    <Grid container spacing={1} mt={1} >
                        <Grid item xs={12} md={4} lg={4}> 
                             <MDBox
                                    display="flex"
                                    flexDirection="column"
                                    alignItems="flex-start"
                                    width="100%"
                                    mr={2}
                                >
                                    <MDTypography variant="body2" color="text" ml={1} fontWeight="regular">
                                        نام
                                    </MDTypography>
                                    <MDBox mb={2} width="100%">
                                        <MDInput
                                            type="name"
                                            fullWidth
                                            name="name"
                                            value={user.name}
                                            onChange={changeHandler}
                                            error={errors.nameError}
                                        />
                                        {errors.nameError && (
                                            <MDTypography variant="caption" color="error" fontWeight="light">
                                                فیلد نام اجباری می باشد.
                                            </MDTypography>
                                        )}
                                </MDBox>
                            </MDBox>
                        </Grid>
                        <Grid item xs={12} md={4} lg={4}> 
                            <MDBox
                                display="flex"
                                flexDirection="column"
                                alignItems="flex-start"
                                width="100%"
                                mr={2}
                            >
                                <MDTypography variant="body2" color="text" ml={1} fontWeight="regular">
                                    نام خانوادگی
                                </MDTypography>
                                <MDBox mb={2} width="100%">
                                    <MDInput
                                        type="lastName"
                                        fullWidth
                                        name="lastName"
                                        value={user.lastName}
                                        onChange={changeHandler}
                                        error={errors.lastNameError}
                                    />
                                    {errors.lastNameError && (
                                        <MDTypography variant="caption" color="error" fontWeight="light">
                                            فیلد نام خانوادگی اجباری می باشد.
                                        </MDTypography>
                                    )}
                                </MDBox>
                            </MDBox>
                        </Grid>
                        <Grid item xs={12} md={4} lg={4}> 
                            <MDBox
                                display="flex"
                                flexDirection="column"
                                alignItems="flex-start"
                            >
                                <MDTypography variant="body2" color="text" ml={1} fontWeight="regular">
                                    شماره تماس
                                </MDTypography>
                                <MDBox mb={1} width="100%">
                                    <MDInput
                                        type="mobile"
                                        fullWidth
                                        name="mobile"
                                        value={user.mobile}
                                        onChange={changeHandler}
                                        error={errors.mobileError}
                                        disabled={isDemo}
                                    />
                                    {errors.mobileError && (
                                        <MDTypography variant="caption" color="error" fontWeight="light">
                                            فیلد شماره تماس اجباری می باشد.
                                        </MDTypography>
                                    )}
                                </MDBox>
                                {isDemo && (
                                    <MDTypography variant="caption" color="text" fontWeight="light">
                                        In the demo version the mobile can not be updated
                                    </MDTypography>
                                )}
                            </MDBox>
                        </Grid>
                        <Grid item xs={12} md={4} lg={3}> 
                            <MDButton fullWidth variant="gradient" color="info" type="submit">
                                    ثبت
                            </MDButton>
                        </Grid>
                    </Grid>
               
                </MDBox>
            </Header>

        </DashboardLayout>
    );
};

export default UserProfile;
