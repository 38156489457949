// @mui material components
import Grid from "@mui/material/Grid";
import {Checkbox, FormControlLabel, FormGroup, InputLabel, MenuItem, Select, Skeleton, TextField} from "@mui/material";
import FormControl from '@mui/material/FormControl';
// Material Kit 2 React components
import MDBox from "components/MDBox";


// Images
import React, {useEffect, useState} from "react";
import MDTypography from "../../../components/MDTypography";
import MDButton from "../../../components/MDButton";
import ClientOrderService from "../../../services/client-order-service";
import {useLocation, useParams} from "react-router-dom";
import Card from "@mui/material/Card";
import DashboardLayout from "../../../examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../../../examples/Navbars/DashboardNavbar";
import MDInput from "../../../components/MDInput";
import packageService from "services/package-service";
import { numberFormat } from "services/helperFunctions";



function OrderDetails() {
    const { id } = useParams(); 
    const [order, setOrder] = useState({});
    const [category, setCategory] = useState(null);
    const [bookbinding, setBookbinding] = useState(false);
    const [bookbindingBox, setBookbindingBox] = useState(true);
    const [bookbindingSellerPackages, setBookbindingSellerPackages] = useState([]);
    const [selectedBookbindingSellerPackage, setSelectedBookbindingSellerPackage] = useState(null);
    const [bookbindingSellerPackagesBox, setBookbindingSellerPackagesBox] = useState([]);
    const [dualShipping, setDualShipping] = useState(false);
    const [description, setDescription] = useState('');
    const location = useLocation();


    const useQuery = () => {
        return new URLSearchParams(location.search);
    };
    const fetchOrder = async (orderId) => {
        
        const response = await ClientOrderService.order([], orderId);

        if (response.data){
            setOrder(response.data)
        }
    };

    const addOrderItem = async () => {
        let orderItemsData = []; // Initialize as an empty array
        if (selectedBookbindingSellerPackage) {
            orderItemsData.push({
                order_id: id,
                count: 1,
                saler_package_id: selectedBookbindingSellerPackage,
            });
        }
        
        // Construct orderData
        let orderData = {
            dual_shipping: dualShipping,
            description: description,
            orderItems: orderItemsData, // Use the updated orderItemsData
        };
        
        const response = await ClientOrderService.addOrderItem(orderData, id);

        if (response.status == 'success'){

            window.location.href='/create-order/step3/'+id
        }
    };


    const fetchCategorySellersPackages = async (categoryId) => {
            
        const response = await packageService.sellerCategorySellersPackages(categoryId, order.saler_id);

        if (response.status == 'success'){
            const packages = response.data.children_recursive.flatMap(child => 
                child.package.flatMap(pkg => 
                    pkg.seller_packages.map(sellerPackage => ({
                        ...sellerPackage,
                        packageTitle: pkg.title, // Include package title for display
                    }))
                )
            );
            setBookbindingSellerPackages(packages);
            
        }
    };

    // const fetchPackageSellerPackages = async (packageId) => {
            
    //     const response = await packageService.packageSellerPackages(packageId, order.saler_id);

    //     if (response.status == 'success'){
    //         setBookbindingSellerPackages(response.data)
    //     }
    // };

    const bookbindingHandler = (e) => {
        
        const isChecked = e.target.checked;
        setBookbinding(isChecked); 
    
        // Only fetch if the checkbox is checked
        if (isChecked) {
            fetchCategorySellersPackages(4);
        }else{
            setBookbindingSellerPackagesBox([])
            setSelectedBookbindingSellerPackage(null)
        }
    }


    const categoryHandler = (categoryId) => {

        switch (categoryId){
            case '1' : 
            //Print Category            
                // setBookbindingBox(true)
            
            break;

            case '2' : 
               
            break;

            case '3' : 
            
            
            break;

            case '4' : 
                
            break;
           }
    }
    
    const descriptionHandler = (e) => {
        setDescription(e.target.value); 
    }

    const dualShippingHandler = (e) => {
        setDualShipping(e.target.checked); 
    }

    const bookbindingSellerPackageHandler = (e) => {
        
        setSelectedBookbindingSellerPackage(e.target.value)
    }

    const query = useQuery();

    useEffect(() => {
        categoryHandler(category)
    }, [category]);


    useEffect(() => {
        setBookbindingSellerPackagesBox(bookbindingSellerPackages.map(sellerPackage => (
            <MenuItem key={sellerPackage.id} value={sellerPackage.id}>
                {`${sellerPackage.title} ( ${numberFormat(sellerPackage.price.price)} تومان )`}
            </MenuItem>
        )));
    }, [bookbindingSellerPackages]);


    useEffect(() => {
        setCategory(query.get('category'))
        
        fetchOrder(id)
    }, []);

  return (
      <DashboardLayout>
          <DashboardNavbar/>
          <Card>
              <MDBox
                  mx={2}
                  mt={-2}
                  py={2}
                  px={2}
                  variant="gradient"
                  bgColor="secondary"
                  borderRadius="lg"
                  coloredShadow="secondary"
              >
                  <MDTypography variant="h6" color="white">
                      جزییات سفارش
                  </MDTypography>
              </MDBox>     
              <Grid container spacing={3}  alignItems="center" sx={{ mx: "auto" }}>
              <Grid item xs={12} px={5} mt={5}>
                      <MDBox
                          display="flex"
                          flexDirection="column"
                          alignItems="flex-start"
                          width="100%">
                            <FormGroup>
                            {bookbindingBox && (
                                <FormControlLabel
                                    control={<Checkbox checked={bookbinding} onChange={bookbindingHandler} />}
                                    labelPlacement="start"
                                    label="صحافی میخام"
                                />
                            )}
                            {bookbinding && bookbindingSellerPackagesBox.length == 0 ? (
                                
                                <Grid item xs={12}>
                                <MDBox mt={1} mb={1} ml={2}>
                                    <FormControl fullWidth>
                                         <Skeleton sx={{ height: 45 }} animation="wave" variant="rectangular" />     
                                    </FormControl>
                                </MDBox>
                            </Grid>) : ''}
                            {bookbinding && bookbindingSellerPackagesBox.length > 0 && (
                                <Grid item xs={12}>
                                    <MDBox mt={1} mb={1} ml={2}>
                                        <FormControl fullWidth>
                                            <InputLabel id="package-input-label">لیست قیمت صحافی</InputLabel>
                                            <Select
                                                labelId="package-input-label"
                                                id="demo-simple-select"
                                                label="لیست قیمت های فنر زنی"
                                                name="size"
                                                onChange={bookbindingSellerPackageHandler}
                                            >
                                                {bookbindingSellerPackagesBox}
                                            </Select>
                                        </FormControl>
                                    </MDBox>
                                </Grid>
                            )}
                                <MDBox display='column'>
                                <FormControlLabel control={<Checkbox   checked={dualShipping} onChange={dualShippingHandler} />} labelPlacement="start" label="پیک دو مقصده" />
                                <MDTypography ml={3} variant="caption" display="block" gutterBottom>مخصوص سفارش هایی که مرسوله را از شما تحویل گرفته و به دفتر فنی مربوطه می رساند</MDTypography>
                                </MDBox>

                                <MDBox display='column'>
                                <FormControlLabel control={<Checkbox disabled checked={dualShipping ? false : true}  />} labelPlacement="start" label="پیک" />
                                <MDTypography ml={3} variant="caption" display="block" gutterBottom>در مرحله بعد آدرس شما گرفته و بعد از اماده سازی سفارش توسط دفتر فنی مرسوله به دستتان خواهد رسید</MDTypography>
                                </MDBox>
                            </FormGroup>
                      </MDBox>
                  </Grid>
                  
                  <Grid item xs={12} px={5} mt={5}>
                      <MDBox
                          display="flex"
                          flexDirection="column"
                          alignItems="flex-start"
                          width="100%">
                          <FormControl fullWidth>
                        
                              <MDInput multiline  onChange={descriptionHandler} rows={4} name="descritpion" id="outlined-basic"  label="توضیحات" variant="outlined" />
                              <MDTypography mt={1} variant="caption" display="block" gutterBottom>
                                ( توضیحات سفارش خود را در قسمت بالا وارد کنید)
                              </MDTypography>

                          </FormControl>
                      </MDBox>
                  </Grid>
                  <Grid item xs={12} px={5} py={2}>
                      <MDBox>
                        <MDButton 
                            fullWidth 
                            component="label" 
                            width="100%"
                            color="info"
                            onClick={addOrderItem}
                            loading>
                                  ثبت
                        </MDButton>
                      </MDBox>
                  </Grid>
              </Grid>
          </Card>
      </DashboardLayout>

  );
}

export default OrderDetails;
