// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";

// Material Kit 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

import bgImage from "assets/images/copyimage2.jpg";
import {Link} from "react-router-dom";
function BuiltByDevelopers() {

  return (
    <MDBox
      display="flex"
      alignItems="center"
      borderRadius="xl"
      my={2}
      py={6}
      sx={{
        backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
          `${linearGradient(
            rgba(gradients.dark.main, 0.8),
            rgba(gradients.dark.state, 0.8)
          )}, url(${bgImage})`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",

      }}
    >
      <Container>
        <Grid container item xs={12} lg={6} sx={{ ml: { xs: 0, lg: 6 } }} display='block'>
          <MDTypography variant="h3" color="white" fontWeight="bold">
              زمان رو بخر
          </MDTypography>
          <MDTypography variant="h6" color="white" mb={1}>
              چرا انلاین سفارش بدیم؟
          </MDTypography>
          <MDTypography variant="body1" color="white" opacity={0.8} mb={2}>
              چون ایزی چاپ فضایی رو فراهم کرده که میتونی از توی خونه تمام دفاتر فنی رو با هم یکجا ببینی مقایسه کنی و سفارش بدی 
          </MDTypography>
            <MDBox>
                <MDTypography
                    component={Link}
                    to={"/create-order"}
                    href={(e) => e.preventDefault()}
                    target=""
                    rel={"noreferrer"}
                    variant="body2"
                    color="white"
                    fontWeight="regular"
                    display = "flex"
                    sx={{
                        alignItems: "center",

                        "& .material-icons-round": {
                            fontSize: "1.125rem",
                            transform: `translateX(3px)`,
                            transition: "transform 0.2s cubic-bezier(0.34, 1.61, 0.7, 1.3)",
                        },

                        "&:hover .material-icons-round, &:focus .material-icons-round": {
                            transform: `translateX(6px)`,
                        },
                    }}
                >
                    ایجاد سفارش <Icon sx={{ fontWeight: "bold" }}>arrow_forward</Icon>
                </MDTypography>
            </MDBox>
        </Grid>
      </Container>
    </MDBox>
  );
}

export default BuiltByDevelopers;
