
// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import PackageCard from '../../examples/Cards/StatisticsCards/PackageCard'
// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";

// Data
import React, {useEffect, useState} from "react";
import DashboardNavbar from "../../examples/Navbars/DashboardNavbar";
import MDTypography from "../../components/MDTypography";
import OrderHandler from "./data/orderHandler";
import FilledInfoCard from "../../examples/Cards/InfoCards/FilledInfoCard";
import packageService from "services/package-service";
import Swal from "sweetalert2";
import { CardHeader, Modal, Skeleton } from "@mui/material";
import { numberFormat } from "services/helperFunctions";
import { defaultLogo } from "services/helperFunctions";
import { useLocation } from "react-router-dom";
import orderService from "services/order-service";
import { persianNumber } from "services/helperFunctions";

function CrateOrder() {
    const [categories, setCategories] = useState([]);
    const [range, setRange] = useState(null);
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [sellerPackageButtonColor, setSellerPackageButtonColor] = useState('info');
    
    const [firstChildCategories, setFirstChildCategories] = useState([]);
    const [selectedFirstChildCategory, setSelectedFirstChildCategory] = useState(null);
    const [secondChildCategories, setSecondChildCategories] = useState([]);
    const [selectedSecondChildCategory, setSelectedSecondChildCategory] = useState(null);    
    const [thirdChildCategories, setThirdChildCategories] = useState([]);
    const [selectedThirdChildCategory, setSelectedThirdChildCategory] = useState(null);
    const [forthChildCategories, setForthChildCategories] = useState([]);
    const [selectedForthChildCategory, setSelectedForthChildCategory] = useState(null);

    const [selectLoading, setSelectLoading] = useState(false);
    const [selectedPackage, setSelectedPackage] = useState(null);
    const [copyCount, setCopyCount] = useState(1);
    const [description, setDescription] = useState('');
    const [packages, setPackages] = useState([]);
    const [sellerPackages, setSellerPackages] = useState([]);
    const [selectedSellerPackage, setSelectedSellerPackage] = useState([]);
    const [sellerPackagesBox, setSellerPackagesBox] = useState([]);
    const [price, setPrice] = useState(0);
    const [open, setOpen] = React.useState(false);
    const [fileLoading, setFileLoading] = React.useState(false);
    const [file, setFile] = useState({uploaded : false, pageCount : null, error : null, name: null, ext: null});
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const location = useLocation();

    const useQuery = () => {
      return new URLSearchParams(location.search);
    };


    
    const fetchCategorySubCategories = async (categoryId) => {
        
        const response = await packageService.categorySubCategories(categoryId);
        if(response.status == 'success'){
            console.log(response.data);
            if(response.data.children_recursive.length == 0){
                
                fetchCategoryPackages(categoryId)
            }else{
                if(selectedFirstChildCategory && selectedSecondChildCategory == null && selectedThirdChildCategory == null){
    
                
                    setSecondChildCategories(response.data.children_recursive)
                }else if(selectedFirstChildCategory && selectedSecondChildCategory && selectedThirdChildCategory == null){
    
                    setThirdChildCategories(response.data.children_recursive)

                }else if(selectedFirstChildCategory && selectedSecondChildCategory && selectedThirdChildCategory  && selectedForthChildCategory == null){
    
                    setForthChildCategories(response.data.children_recursive)

                }else if (selectedFirstChildCategory == null && selectedSecondChildCategory == null && selectedThirdChildCategory == null && selectedForthChildCategory == null){

                    setFirstChildCategories(response.data.children_recursive)
                }
            }
            
        }
    };

    useEffect(() => {
        
        if(selectedFirstChildCategory){
            
            fetchCategorySubCategories(selectedFirstChildCategory)
        }
    }, [selectedFirstChildCategory]);


    
    useEffect(() => {

        if(selectedThirdChildCategory){
            
            fetchCategorySubCategories(selectedThirdChildCategory)
        }
    }, [selectedThirdChildCategory]);



    
    useEffect(() => {
        console.log('in selectedForthChildCategory');
        
        if(selectedForthChildCategory){
            
            fetchCategorySubCategories(selectedForthChildCategory)
        }
    }, [selectedForthChildCategory]);


    
    useEffect(() => {

        if(selectedFirstChildCategory){
            
            fetchCategorySubCategories(selectedFirstChildCategory)
        }
    }, [selectedFirstChildCategory]);


    useEffect(() => {

        if(selectedSecondChildCategory){
            
            fetchCategorySubCategories(selectedSecondChildCategory)
        }
    }, [selectedSecondChildCategory]);



    const firstChildCategoriesHandler = (e) => {
        setPackages([])
        setRange(null)
        setSelectedPackage(null)
        setSecondChildCategories([])
        setSelectedSecondChildCategory(null)
        setThirdChildCategories([])
        setSelectedThirdChildCategory(null)
        setForthChildCategories([])
        setSelectedForthChildCategory(null)
      
        setSelectedFirstChildCategory(e.target.value)
    };

    const secondChildCategoriesHandler = (e) => {

        setPackages([])
        setRange(null)
        setThirdChildCategories([])
        setSelectedPackage(null)
        setSelectedThirdChildCategory(null)
        setForthChildCategories([])
        setSelectedForthChildCategory(null)
        setSelectedSecondChildCategory(e.target.value)
        // fetchCategoryPackages(e.target.value)
    };


    const thirdChildCategoriesHandler = (e) => {        
        setPackages([])
        setRange(null)
        setSelectedPackage(null)
        setForthChildCategories([])
        setSelectedForthChildCategory(null)
        setSelectedThirdChildCategory(e.target.value)
    };



    const forthChildCategoriesHandler = (e) => {
        
        setPackages([])
        setRange(null)
        setSelectedPackage(null)
        setSelectedForthChildCategory(e.target.value)
    };



    const fetchPackageSellersPackages = async (id) => {
        
        const response = await packageService.packageSellersPackages(id, range);
        if(response.status == 'success'){
            setSellerPackages(response.data)
        }
    };
    
    const fetchCategoryPackages = async (id) => {
        
        const response = await packageService.categoryPackages(id);
        if(response.status == 'success'){
            
            
            setPackages(response.data)
        }

        setSelectLoading(false)
    };


    
    const fetchSellerPackage = async (id) => {
        

        const response = await packageService.sellerPackage(id);
        if(response.status == 'success'){

            
            setSelectedSellerPackage(response.data)
            // setSelectedCategory(response.data.package.category.id)
   
            
            fetchCategoryPackages(response.data.package.category.id)
            setSelectedPackage(response.data.package.id)
    
        }
     
    
    };



    const fetchCategories = async () => {

        const response = await packageService.categories();
        if(response.status = 'success'){

            setCategories(response.data)
        }

    };

    const categoryHandler = (e) => {
        setSelectLoading(true)
        setSelectedCategory(e.target.value)
        setSelectedSellerPackage([])
        setSelectedPackage(null)
        setFirstChildCategories([])
        setSelectedFirstChildCategory(null)
        setSecondChildCategories([])
        setSelectedSecondChildCategory(null)
        setThirdChildCategories([])
        setSelectedThirdChildCategory(null)
        setForthChildCategories([])
        setSelectedForthChildCategory(null)
    };



    const copyCountHandler = (e) => {
        
        setCopyCount(persianNumber(e.target.value))
    };
    const descriptionHandler = (e) => {
        
        setDescription(e.target.value)
    };

    const priceHandler = (price, pdfCount, copyCount) => {
        let totalPrice
        if(pdfCount){

            totalPrice = price*pdfCount*copyCount
        }else{

            totalPrice = price*copyCount
        }
        setPrice(totalPrice) 
    };

    
    
    const packageHandler = (e) => {
        
        setSelectedSellerPackage([])
        setSellerPackagesBox([
            <MDBox> {/* Add a unique key for the loading state */}
                <MDTypography variant="body1">
                    در حال جستوجو دفاتر فنی
                </MDTypography>
            </MDBox>
        ]);
        setSelectedPackage(e.target.value)
    };

    const selectedSellerPackageHandler = (sellerPackage) => {
        
        handleClose()
        setSelectedSellerPackage(sellerPackage)

    };

    const rangeHanlder = (e) => {
        setRange(e.target.value)
    };
    

    const openSellerModal = (currentPackage) => {        
        if(currentPackage){
            setSellerPackagesBox([
                <MDBox p={2}>
                    <MDBox mt={2}>
                        <Card>
                            <CardHeader
                                    title={<Skeleton
                                    animation="wave"
                                    height={30}
                                    width="80%"
                                    style={{ marginBottom: 6 }}
                                    />}
                                />
                            <Skeleton sx={{ height: 190 }} animation="wave" variant="rectangular" />
                        </Card>
                    </MDBox>
                    <MDBox mt={2}>
                        <Card >
                            <CardHeader
                                    title={<Skeleton
                                    animation="wave"
                                    height={30}
                                    width="80%"
                                    style={{ marginBottom: 6 }}
                                    />}
                                />
                            <Skeleton sx={{ height: 190 }} animation="wave" variant="rectangular" />
                        </Card>
                    </MDBox>
                </MDBox>
            ]);

            handleOpen()
            fetchPackageSellersPackages(currentPackage)
        }else{
            Swal.fire({
                title: "خطا",
                text: "ابتدا دسته بندی و پکیج مورد نظر خود را انتخاب کنید",
                icon: "error"
            });
        }
       
    };

    const uploadFileToServer = async (_file) => {
        const formData = new FormData();
        formData.append('file', _file);

        try{
            const response = await orderService.uploadFile(formData);
            if (response.status == 'success'){
                setFile({
                    ...file,
                    uploaded: true,
                    name: response.data.file,
                    ext: response.data.fileExtension,
                    pageCount: response.data.pageCount,
                    error: null
                });
            }
        }catch (error){
            setFile({
                ...file,
                uploaded: false,
                name: null,
                ext: null,
                error: error.messages
            });
        }
      
        setFileLoading(false)

    };

    const fileUploadHandler =  (e) => {
        setFileLoading(true)
        uploadFileToServer(e.target.files[0])
    };

    const orderCreateHandler = async () => {
        
        if(selectedSellerPackage.id){
           

            let orderItemData = {}
            orderItemData['saler_package_id'] = selectedSellerPackage.id
            orderItemData['file_page_count'] = file.pageCount
            orderItemData['description'] = description
            if(selectedCategory == 27){

                orderItemData['category_id'] = 27
            }
            orderItemData['count'] = copyCount
            orderItemData['file'] = file.name
            orderItemData['saler_id'] = selectedSellerPackage.saler.id
            
            const response = await orderService.createOrder(orderItemData);
    
            if (response.success){
                if(selectedCategory == 27){
                    
                      Swal.fire({
                        title: "سفارش شما ثبت شد",
                        text: " به زودی کارشناسان ما با شما تماس خواهند گرفت",
                        icon: "success",
                        confirmButtonText: "بازگشت به صفحه اصلی"
                      }).then((result) => {
                        
                        window.location.href='/orders'
                      });

                }else{

                    window.location.href='/create-order/step2/'+response.data.order_id+'?category='+selectedCategory
                }
            }else{
    
    
                Swal.fire({
                    title: "مشکلی پیش آمده",
                    text: response.message.file[0],
                    icon: "question"
                });
            }
        }else{
            Swal.fire({
                title: "خطا",
                text: 'لطفا دفتر فنی مورد نظر خود را انتخاب نمایید',
                icon: "error"
            });
        }
       
    };



    useEffect(() => {
        if(selectedSellerPackage.id){
            setSellerPackageButtonColor('success')
            priceHandler(selectedSellerPackage.price.price, file.pageCount, copyCount)
        }
        
    }, [copyCount, selectedSellerPackage, file.pageCount,]);


    useEffect(() => {
        if(secondChildCategories.length > 0){
            
            let secondChildCategoryId = query.get('second-child-category')
            if(secondChildCategoryId){
    
           
                    setSelectedSecondChildCategory(parseInt(secondChildCategoryId))
            }
        }
        
    }, [secondChildCategories]);

    

    useEffect(() => {
        if(firstChildCategories.length > 0){
        
            
            let firstChildCategoryId = query.get('first-child-category')
            if(firstChildCategoryId){
    
           
                    setSelectedFirstChildCategory(parseInt(firstChildCategoryId))
            }
        }
        
    }, [firstChildCategories]);

    useEffect(() => {
        
        if(selectedCategory){
            setFirstChildCategories([{
                title : 'درحال جستجو',
                id : null
            }])
            fetchCategorySubCategories(selectedCategory)
        }
    }, [selectedCategory]);

    useEffect(() => {

        if(sellerPackages.length>0){
            setSellerPackagesBox(sellerPackages.map((sellerPackage) => {
                
                return <MDBox mb={4}>
                           <PackageCard
                                   color={sellerPackage.saler.isOpen ? 'info' : 'error'} 
                                   logo={sellerPackage.saler.logo ?? defaultLogo}
                                   title={sellerPackage.title}
                                   rating={sellerPackage.saler.average_rate ?? 'تازه وارد'}
                                   number={selectedCategory != 27 ? numberFormat(sellerPackage.price.price)+ ' تومان' : ''}
                                   subTitle={sellerPackage.saler.name}
                                   description={sellerPackage.saler.address}
                                   buttonTitle={sellerPackage.saler.isOpen ? 'ثبت سفارش' : 'پیش سفارش'}
                                   badge={sellerPackage.saler.isOpen}
                                   buttonValue={sellerPackage}
                                   buttonAction={selectedSellerPackageHandler}
                               />
                       </MDBox>
                }))
        }
        else{   
            setSellerPackagesBox([
                <MDBox>
                    <MDTypography variant="body1">
                       موردی یافت نشد 
                    </MDTypography>
                </MDBox>
            ]);
        }
        

    }, [sellerPackages]);

    const query = useQuery();
    useEffect(() => {

    
        let packageId = query.get('package')
        let categoryId = query.get('category')
        if(categoryId){
            
            setSelectedCategory(parseInt(categoryId))

          
        }
        if(packageId){
            fetchSellerPackage(parseInt(packageId))
        }
        fetchCategories();

    }, []);


    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '90%', // Use a percentage for responsiveness
        maxWidth: '600px', // Set a max width for larger screens
        maxHeight: '80vh', // Limit height to viewport height
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 4,
        overflowY: 'auto', // Enable vertical scrolling
        overflowX: 'hidden', // Prevent horizontal scrolling
        '-ms-overflow-style': 'none', // For Internet Explorer and Edge
    };
    
    // For Webkit browsers (Chrome, Safari)
    const webkitStyle = {
      '&::-webkit-scrollbar': {
        display: 'none', // Hide scrollbar
      },
    };
    
    // Combine styles if using a styling solution that supports them
    const combinedStyle = {
      ...style,
      ...webkitStyle,
    };

    return (
        <DashboardLayout>
            <DashboardNavbar/>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                >
                    <Card sx={combinedStyle}>
                    {sellerPackagesBox}
                    </Card>
            </Modal>
        <MDBox py={3}>
                <Grid container spacing={3}>
                    <Grid item xs={12} md={12}>
                        <Card>
                            <MDBox
                                mx={2}
                                mt={-1}
                                py={2}
                                px={2}
                                variant="gradient"
                                bgColor="warning"
                                borderRadius="lg"
                                coloredShadow="secondary"
                                 mb={3}
                            >
                                <MDTypography variant="h6" color="white">
                                    ثبت سفارش
                                </MDTypography>
                            </MDBox>
                            <Grid container>
                                <Grid item xs={12} md={6}>

                                    {categories.length>0 ? (
                                        <OrderHandler 
                                                 sellerPackageButtonColor={sellerPackageButtonColor}
                                                 categoryHandler={categoryHandler}
                                                 packageHandler={packageHandler}
                                                 copyCountHandler={copyCountHandler}
                                                 description={description}
                                                 descriptionHandler={descriptionHandler}
                                                 orderCreateHandler={orderCreateHandler}
                                                 selectedFirstChildCategory={selectedFirstChildCategory}
                                                 selectedSecondChildCategory={selectedSecondChildCategory}
                                                 firstChildCategories={firstChildCategories}
                                                 firstChildCategoriesHandler={firstChildCategoriesHandler}
                                                 secondChildCategories={secondChildCategories}
                                                 secondChildCategoriesHandler={secondChildCategoriesHandler}
                                                 thirdChildCategories={thirdChildCategories}
                                                 thirdChildCategoriesHandler={thirdChildCategoriesHandler}

                                                 forthChildCategories={forthChildCategories}
                                                 forthChildCategoriesHandler={forthChildCategoriesHandler}
                                                 rangeHanlder={rangeHanlder}
                                                 file={file}
                                                 fileUploadHandler={fileUploadHandler}
                                                 fileLoading={fileLoading}
                                                 price={price}
                                                 selectLoading={selectLoading}
                                                 categories={categories}
                                                 openSellerModal={openSellerModal}
                                                 selectedSellerPackage={selectedSellerPackage}
                                                 selectedPackage={selectedPackage}
                                                 selectedCategory={selectedCategory}
                                                 copyCount={copyCount}
                                                 packages={packages}
                                                />
                                            ) : (
                                                <>
                                                <MDBox p={2}>
                                                    <Skeleton  height={50} variant="rectangular" />
                                                </MDBox>
                                                <MDBox p={2}>
                                                    <Skeleton  height={50} variant="rectangular" />
                                                </MDBox>
                                                <MDBox p={2}>
                                                    <Skeleton  height={50} variant="rectangular" />
                                                </MDBox>
                                                <MDBox p={2}>
                                                    <Skeleton  height={50} variant="rectangular" />
                                                </MDBox>
                                                </>
                                            )}
                                    
                                
                                </Grid>
                                {/* <Grid item xs={12} md={6} p={2}>
                                    <FilledInfoCard
                                        variant="gradient"
                                        color="warning"
                                        icon="currency_exchange"
                                        title="ارسال رایگان"
                                        description="با ایجاد اولین سفارش خود از 100% تخفیف ارسال به هر نقطه ای از شهر بهره من شوید"
                                        action={{
                                            type: "internal",
                                            route: "#",
                                            label: "همین الان شروع کن",
                                        }}
                                    />
                                </Grid> */}
                                <Grid item xs={12} p={2}>
                                    <FilledInfoCard
                                        variant="gradient"
                                        color="info"
                                        icon="help_center"
                                        title="پشتیبانی"
                                        description="برای گرفتن راهنمایی یا هر سوالی که داری توی پشتیبانی ما در سریع ترین زمان پاسخ داده میشه"
                                        action={{
                                            type: "external",
                                            route: "https://t.me/alirezaroustae",
                                            label: "پیام به پشتیبانی",
                                        }}
                                    />
                                </Grid>
                            </Grid>
                        </Card>
                    </Grid>
                </Grid>
        </MDBox>
</DashboardLayout>
)
    ;
}

export default CrateOrder;
