/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// react-router-dom components
import { Link } from "react-router-dom";

// @mui material components
import Icon from "@mui/material/Icon";
import MuiLink from "@mui/material/Link";

// Material Kit 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

function FilledInfoCard({ variant, color, icon, title, description, action }) {
  const buttonStyles = {
    width: "max-content",
    display: "flex",
    alignItems: "center",
    fontSize: {
      xs: '0.8rem', // Small screens
      sm: '0.9rem',  // Medium screens
      lg: '1rem',    // Large screens
    },
    lineHeight: {
      xs: 1.5,
      sm: 1.6,
      md: 1.7,
      lg: 1.8,
    },
    "& .material-icons-round": {
      fontSize: "1.125rem",
      transform: `translateX(3px)`,
      transition: "transform 0.2s cubic-bezier(0.34, 1.61, 0.7, 1.3)",
    },
    "&:hover .material-icons-round, &:focus .material-icons-round": {
      transform: `translateX(6px)`,
    },
  };
  

  let iconColor = color;

  if (variant === "gradient" && color !== "light") {
    iconColor = "white";
  } else if (variant === "gradient" && color === "light") {
    iconColor = "dark";
  }

  return (
    <MDBox
      variant={variant}
      bgColor={variant === "contained" ? "grey-100" : color}
      borderRadius="xl"
      pt={2.5}
      pb={2}
      px={2}
      sx={{
        fontSize: {
          xs: '0.8rem', // Small screens
          sm: '0.9rem',   // Large screens
          lg: '1rem',  // Extra-large screens
          },
          lineHeight: {
            xs: 1.5,
            sm: 1.6,
            md: 1.7,
            lg: 1.8,
          }, }}
    >
      <MDTypography
        display="block"
        variant="h3"
        color={iconColor}
        textGradient={variant === "contained"}
        mt={-0.625}
      >
        {typeof icon === "string" ? <Icon>{icon}</Icon> : icon}
      </MDTypography>
      <MDBox pt={{ xs: 0, md: 0 }} pl={{ xs: 0, md: 2 }} overflow="hidden">
      <MDTypography
        display="block"
        variant="5"
        color={variant === "contained" || color === "light" ? "dark" : "white"}
        fontWeight="bold"
        mb={1}
        sx={{
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
          fontSize: {
            xs: title.length > 20 ? '0.6rem' : '0.7rem', // Adjust based on title length
            sm: title.length > 20 ? '0.8rem' : '0.9rem',
            lg: title.length > 20 ? '0.9rem' : '1rem',
          },
          lineHeight: {
            xs: 1.5,
            sm: 1.6,
            md: 1.7,
            lg: 1.8,
          }
        }}
      >
        {title}
      </MDTypography>

        <MDTypography
          display="block"
          variant="body2"
          color={variant === "contained" || color === "light" ? "text" : "white"}
          mb={2}
          sx={{ fontSize: {
            xs: '0.8rem', // Small screens
            sm: '0.9rem',   // Large screens
            lg: '1rem',  // Extra-large screens
            },
            lineHeight: {
              xs: 1.5,
              sm: 1.6,
              md: 1.7,
              lg: 1.8,
            }}}
        >
          {description}
        </MDTypography>
        {action && action.type === "external" ? (
          <MDTypography
            component={MuiLink}
            href={action.route}
            target="_blank"
            rel="noreferrer"
            variant="body2"
            fontWeight="regular"
            color={variant === "contained" ? color : "white"}
            sx={buttonStyles}
          >
            {action.label} <Icon sx={{ fontWeight: "bold" }}>arrow_forward</Icon>
          </MDTypography>
        ) : null}
        {action && action.type === "internal" ? (
          <MDTypography
            component={Link}
            to={action.route}
            variant="body2"
            fontWeight="regular"
            color={variant === "contained" ? color : "white"}
            sx={buttonStyles}
          >
            {action.label} <Icon sx={{ fontWeight: "bold" }}>arrow_forward</Icon>
          </MDTypography>
        ) : null}
      </MDBox>
    </MDBox>
  );
}

// Setting default props for the FilledInfoCard
FilledInfoCard.defaultProps = {
  variant: "contained",
  color: "info",
  action: false,
};

// Typechecking props for the FilledInfoCard
FilledInfoCard.propTypes = {
  variant: PropTypes.oneOf(["contained", "gradient"]),
  color: PropTypes.oneOf([
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "light",
    "dark",
  ]),
  icon: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  action: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.shape({
      type: PropTypes.oneOf(["external", "internal"]).isRequired,
      route: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    }),
  ]),
};

export default FilledInfoCard;
