/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import TimelineItem from "examples/Timeline/TimelineItem";

function OrdersOverview() {
  return (
    <Card sx={{ height: "100%" }}>
        <MDBox
            mx={2}
            mt={-1}
            py={2}
            px={2}
            variant="gradient"
            bgColor="primary"
            borderRadius="lg"
            coloredShadow="primary"
        >
            <MDTypography variant="h6" color="white">
                مراحل ثبت سفارش
            </MDTypography>
        </MDBox>
      <MDBox pt={3} px={3}>
        <MDBox mt={0} mb={2}>
          <MDTypography variant="button" color="text" fontWeight="regular">
            <MDTypography display="inline" variant="body2" verticalAlign="middle">
              <Icon sx={{ color: ({ palette: { success } }) => success.main }}>arrow_upward</Icon>
            </MDTypography>
            &nbsp;
            <MDTypography variant="button" color="text" fontWeight="medium">
             ۳۵ دقیقه
            </MDTypography>{" "}
              متوسط زمان
          </MDTypography>
        </MDBox>
      </MDBox>
      <MDBox p={2}>
        <TimelineItem
          color="primary"
          icon="inventory_1"
          title="ثبت سفارش و ارسال شدن به اپراتور"
          dateTime="9:35"
        />
        <TimelineItem
          color="error"
          icon="inventory_2"
          title="شروع عملیات آماده سازی"
          dateTime="9:40"
        />
        <TimelineItem
          color="info"
          icon="shopping_cart"
          title="تکمیل شدن سفارش و منتظر ارسال"
          dateTime="09:50"
        />
        <TimelineItem
          color="warning"
          icon="moped"
          title="در راه مقصد شما"
          dateTime="09:55"
        />
        <TimelineItem
          color="success"
          icon="done"
          title="به دست شما رسیده"
          dateTime="10:10"
          lastItem
        />
      </MDBox>
    </Card>
  );
}

export default OrdersOverview;
