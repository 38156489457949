/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Sections components

import View from "../home/components/View";

// PageHeaders page components
import HeaderOne from "./components/HeaderOne";

// PageHeaders page components code
import DefaultNavbar from "../../examples/Navbars/DefaultNavbar";
import Information from "../../pages/Presentation/sections/Information";
import BuiltByDevelopers from "../../pages/Presentation/components/BuiltByDevelopers";
import MDBox from "../../components/MDBox";
import Footer from "../../examples/Footer/CenteredFooter";
import FilledInfoCard from "../../examples/Cards/InfoCards/FilledInfoCard";
import categoryData from "../../data/categoriesData";
import Grid from "@mui/material/Grid";
import {Link} from "react-router-dom";
import ExampleCard from "../../pages/Presentation/components/ExampleCard";
import Container from "@mui/material/Container";
import MDTypography from "../../components/MDTypography";
import Icon from "@mui/material/Icon";
import MDCardCustom from "../../components/MDCardCustom";
import { useEffect, useState } from "react";
import sellerService from "services/seller-service";
import { defaultLogo } from "services/helperFunctions";
import packageService from "services/package-service";
import { staticImagesPrefix } from "services/helperFunctions";
import { Card, CardContent, CardHeader, Skeleton } from "@mui/material";
import { date } from "yup";

function Home() {

    const [sellerBoxes, setSellerBoxes] = useState([]);
    const [sellers, setSellers] = useState([]);
    const [categoriesData, setCategoriesData] = useState([]);

    const fetchCategories = async () => {
      
        try {
          const response = await packageService.categories();
          if (response.status == 'success') {
            setCategoriesData(response.data)
          } 
        } catch (error) {
            
        }
     
    };

    const fetchSellers = async () => {
      
        try {
          const response = await sellerService.newSellers();
          if (response.data.status == 'error') {
          } else {
            setSellers(response.data.data);
          }
        } catch (error) {
            
        }
     
    };


    useEffect(() => {
        fetchCategories()
        fetchSellers()
    }, []);

    useEffect(() => {

        setSellerBoxes(sellers.map(seller => (
          <Grid item xs={12} mb={5} key={seller.id}>
              <MDCardCustom
                  title={seller.name}
                  address={seller.address}
                  badge={seller.isOpen}
                  route={`/sellers/${seller.id}/profile`}
                  description={seller.summary}
                  image={seller.logo ?? defaultLogo} // Update image if different for each seller
                  buttonTitle='مشاهده'
                  rating={seller.average_rate ?? 'تازه وارد'} // Update rating as needed
              />
          </Grid>
      )));
    
    }, [sellers]);

    const renderData = categoriesData.map((category) => {
      const isCustomCategory = [40, 41, 42, 43].includes(category.id);
      
      return (
        <Grid item xs={6} lg={3} key={category.id}>
          <Link to={isCustomCategory ? '/create-order/custom?category=' + category.id : '/create-order?category=' + category.id}>
            <ExampleCard 
              image={staticImagesPrefix + '/category_' + category.id + '.png?443123' } 
              display="grid" 
              minHeight="auto" 
            />
          </Link>
        </Grid>
      );
    });
    
    
   
  return (
      <>
          <DefaultNavbar />
       
        <Container>
            <MDBox mt={2} px={1}>
                
                  <Grid container spacing={3}>
                      <Grid item xs={12} sx={{ mt: 10 }}>
                        <MDTypography
                             sx={{
                                fontSize: {
                                    xs: '1.5rem', // For extra-small screens
                                    sm: '2rem',   // For small screens
                                    md: '2.5rem',
                                },
                            }}
                        >
                        محصولات و خدمات 
                        </MDTypography>
                      </Grid>
                      <Grid item xs={12} lg={12}>
                          <Grid container spacing={6}>
                              {renderData.length > 0  ? (renderData) : 
                              (
                                <>
                                <Grid item xs={6} lg={3} key={1}>
                                  <Skeleton  height={90} variant="rounded" />
                                </Grid>
                                <Grid item xs={6} lg={3} key={2}>
                                  <Skeleton  height={90} variant="rounded" />
                                </Grid>
                                <Grid item xs={6} lg={3} key={3}>
                                  <Skeleton  height={90} variant="rounded" />
                                </Grid>
                                <Grid item xs={6} lg={3} key={4}>
                                  <Skeleton  height={90} variant="rounded" />
                                </Grid>
                                <Grid item xs={6} lg={3} key={5}>
                                  <Skeleton  height={90} variant="rounded" />
                                </Grid>
                                </>
                              )}
                          </Grid>
                      </Grid>
                  </Grid>
            </MDBox>

            <MDBox my={2} px={1}>
                  <MDTypography
                      sx={{
                        mb: 3,
                        fontSize: {
                            xs: '1.5rem', // For extra-small screens
                            sm: '2rem',   // For small screens
                            md: '2.5rem',
                        },
                    }}
                  >
                    دفاتر فنی
                  </MDTypography>
                  <Grid container spacing={3}>
                    {sellerBoxes.length > 0 ? sellerBoxes : (<Grid item xs={12}>
                      <Card >
                        <CardHeader
                          avatar={
                            <Skeleton animation="wave" variant="circular" width={40} height={40} />}
                          title={<Skeleton
                            animation="wave"
                            height={10}
                            width="80%"
                            style={{ marginBottom: 6 }}
                          />}
                          subheader={
                            <Skeleton animation="wave" height={10} width="40%" />}
                        />
                      
                        <Skeleton sx={{ height: 190 }} animation="wave" variant="rectangular" />
                      </Card>
                    </Grid>)}
                  </Grid>
          </MDBox>
          <MDBox my={1}>
                <FilledInfoCard
                    variant="gradient"
                    color="info"
                    icon="help_center"
                    title="پشتیبانی"
                    description="برای ایجاد سفارش خودت به راهنمایی نیاز داری ؟ پاسخ گویی سریع در تلگرام"
                    action={{
                        type: "external",
                        route: "https://t.me/alirezaroustae",
                        label: "پیام به پشتیبانی",
                    }}
                />
          </MDBox>
          <MDBox mb={8}>
                <Information />
          </MDBox>

          <MDBox mb={2}>
            <BuiltByDevelopers />
          </MDBox>

          <MDBox>
              <Footer />
          </MDBox>
        </Container>


        


        

          
      </>
  );
}

export default Home;
