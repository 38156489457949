import { useContext, useState } from "react";


// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import MuiLink from "@mui/material/Link";


// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

// Authentication layout components
import BasicLayoutLanding from "layouts/authentication/components/BasicLayoutLanding";

// Images
import bgImage from "assets/images/loginB.png"
import AuthService from "services/auth-service";
import { AuthContext } from "context";
import InstagramIcon from "@mui/icons-material/Instagram";
import {Telegram} from "@mui/icons-material";

function Login() {
  const authContext = useContext(AuthContext);
  const [otpBox, setOtpBox] = useState(false);
  const [credentialsErros, setCredentialsError] = useState(null);

  const [inputs, setInputs] = useState({
    mobile: "",
    otp: "",
  });

  const [errors, setErrors] = useState({
      mobileError: false,
  });


  const changeHandler = (e) => {
    setInputs({
      ...inputs,
      [e.target.name]: e.target.value,
    });
  };

  const submitSendOtpHandler = async (e) => {
    e.preventDefault();

    const newUser = { mobile: inputs.mobile};

      const response = await AuthService.sendOtp(JSON.stringify(newUser));
  
      if (response.status == 'success'){
        setOtpBox(true)
      }
      
    return () => {
      setInputs({
        mobile: "",
      });

      setErrors({
        mobileError: false,
      });
    };
  };


  const editMobileHandler = async (e) => {
    e.preventDefault();
    setInputs({
      mobile: "",
      otp: "",
    });
    setOtpBox(false)
  };

  



  const submitVerifyOtpHandler = async (e) => {
  
    e.preventDefault();

    const newUser = { mobile: inputs.mobile, otp : inputs.otp };

      const response = await AuthService.verifyOtp(JSON.stringify(newUser));
      console.log('asdasdasdsa')

      if (response.status == 'success'){
          authContext.login(response.data.token);
      }else{
          setCredentialsError('کد وارد شده معتبر نمی باشد');
      }
    return () => {
      setInputs({
        mobile: "",
        otp: "",
      });

      setErrors({
        mobileError: false,
        otpError: "",
      });
    };
  };


  return (
    <BasicLayoutLanding>
      <Card>
        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="info"
          mx={2}
          mt={-3}
          p={2}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
            ورود
          </MDTypography>
          <Grid container spacing={3} justifyContent="center" sx={{ mt: 1, mb: 2 }}>
            <Grid item xs={2}>
              <MDTypography component={MuiLink}  target='_blanck' href="https://instagram.com/easychapp" variant="body1" color="white">
                <InstagramIcon color="inherit" />
              </MDTypography>
            </Grid>
            <Grid item xs={2}>
              <MDTypography component={MuiLink} target='_blanck' href="https://t.me/easychapp" variant="body1" color="white">
                <Telegram color="inherit" />
              </MDTypography>
            </Grid>
          </Grid>
        </MDBox>
        <MDBox pt={4} pb={3} px={3}>
        {otpBox ? (
          <MDBox>
            <MDBox mb={2}>
            <MDTypography variant="caption" color="info">
                لطفا کد 5 رقمی ارسال‌شده به {inputs.mobile} را وارد کنید.
              </MDTypography>
            </MDBox>
                
            <MDBox mb={2}>
              <MDInput
                type="text"
                label="کد یک بار مصرف"
                fullWidth
                value={inputs.otp}
                name="otp"
                onChange={changeHandler}
              />
            </MDBox>
            <MDBox mt={4} mb={1}>
              <MDButton variant="gradient" size='large' onClick={submitVerifyOtpHandler} color="info" fullWidth type="submit" loading>
                 ورود  
              </MDButton> 
            </MDBox>
            <MDBox>
                <MDButton mb={1}  variant="text" onClick={editMobileHandler} color="info">
                  ویرایش شماره
                </MDButton>
              </MDBox>
          </MDBox>
        ) : (
          <MDBox>
            <MDBox mb={2}>
              <MDInput
                type="text"
                label="شماره تماس"
                fullWidth
                value={inputs.mobile}
                name="mobile"
                onChange={changeHandler}
                error={errors.mobileError}
              />
            </MDBox>
            <MDBox mt={4}>
              <MDButton variant="gradient" onClick={submitSendOtpHandler} color="info" fullWidth type="submit" loading>
                ورود
              </MDButton>
            </MDBox>      
          </MDBox>
        )}

        </MDBox>
        
      </Card>
    </BasicLayoutLanding>
  );
}

export default Login;
