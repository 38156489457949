import HttpService from "./htttp.service";

class OrderService {

  packages = async (payload) => {

    const packagesEndpoint = 'packages';
    return await HttpService.get(packagesEndpoint, payload);
  };

  
  categories = async () => {

    const categoriesEndpoint = 'client/categories';
    return await HttpService.get(categoriesEndpoint);
  };


  category = async (id) => {

    const categoryEndpoint = 'client/categories/'+id;
    return await HttpService.get(categoryEndpoint);
  };
  
  categorySubCategories = async (id) => {

    const categorySubcategoriesEndpoint = 'client/categories/'+id+'/sub-categories';
    return await HttpService.get(categorySubcategoriesEndpoint);
  };

    
  sellerCategorySellersPackages = async (id, sellerId) => {

    const sellerCategorySellersPackagesEndpoint = 'client/categories/'+id+'/seller-packages/'+sellerId;
    return await HttpService.get(sellerCategorySellersPackagesEndpoint);
  };

  
  categoryPackages = async (id) => {

    const categoryPackagesEndpoint = 'client/categories/'+id+'/packages';
    return await HttpService.get(categoryPackagesEndpoint);
  };

  packageSellersPackages = async (id, range = null) => {

    
    const packageSellersPackagesEndpoint = range ? 'client/packages/'+id+'/sellers-packages?rangeId='+range : 'client/packages/'+id+'/sellers-packages';
    return await HttpService.get(packageSellersPackagesEndpoint);
  };



  packageSellerPackages = async (id, sellerId) => {

    const packageSellersPackagesEndpoint = 'client/packages/'+id+'/seller/'+sellerId+'/seller-packages';
    return await HttpService.get(packageSellersPackagesEndpoint);
  };



  sellerPackage = async (id) => {

    const sellerPackageEndpoint = 'client/seller-package/'+id;
    return await HttpService.get(sellerPackageEndpoint);
  };

  
  packagePrice = async (payload) => {

    const packagePriceEndpoint = 'package-price';
    return await HttpService.post(packagePriceEndpoint, payload);
  };

  todos = async (payload) => {

    const ordersEndpoint = 'orders-todos';
    return await HttpService.post(ordersEndpoint, payload);
  };

  order = async (payload, id) => {
    const orderEndpoint = 'orders/'+id;
    return await HttpService.get(orderEndpoint, payload);
  };
  snappGetRide = async (credentials) => {
    const snappGetRideEndpoint = 'https://customer.snapp-box.com/v1/customer/create_order';
    return await HttpService.post(snappGetRideEndpoint, credentials);
  };

  logout = async () => {
    const logoutEndpoint = 'logout';
    return await HttpService.post(logoutEndpoint);
  };

  forgotPassword = async (payload) => {
    const forgotPassword = 'password-forgot';
    return await HttpService.post(forgotPassword, payload);
  }

  resetPassword = async (credentials) => {
    const resetPassword = 'password-reset';
    return await HttpService.post(resetPassword, credentials);
  }

  getProfile = async() => {
    const getProfile = 'me';
    return await HttpService.get(getProfile);
  }

  updateProfile = async (newInfo) => {
    const updateProfile = "me";
    return await HttpService.patch(updateProfile, newInfo);
  }
}

export default new OrderService();
