import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDBadge from "components/MDBadge";
import Grid from "@mui/material/Grid";
import MDButton from "../../../components/MDButton";
import {Link} from "react-router-dom";
import MopedIcon from '@mui/icons-material/Moped';
import AddCommentIcon from '@mui/icons-material/AddComment';
import {DeleteForever} from "@mui/icons-material";
import CancelIcon from '@mui/icons-material/Cancel';
import {numberFormat, uploadUrl} from "../../../services/helperFunctions";

export default function data(data) {
  

      console.log(data)
    const PackageType = ({ category ,sellerPackage, copyCount, price }) => (
        <MDBox lineHeight={1} textAlign="left">
            <MDTypography display="block" variant="caption" color="text" fontWeight="medium">
                {'دسته بندی : '} {category}
            </MDTypography>
            <MDTypography display="block" variant="caption" color="text" fontWeight="medium">
                {' پکیج : '} {sellerPackage} 
            </MDTypography>
            <MDTypography display="block" variant="caption" color="text" fontWeight="medium">
                {'تعداد : '}{copyCount}
            </MDTypography>
            <MDTypography display="block" variant="caption" color="text" fontWeight="medium">
                {'قیمت : '}{price}
            </MDTypography>
        </MDBox>
    );

    const orderItemHandler = (orderItems) => {
        let count = orderItems.length
        return orderItems.map(item => (
            <Grid key={item.id} item xs={12/count}>
                <PackageType category={item.saler_package.package.category.title} price={item.saler_package.price.price}  sellerPackage={item.saler_package.title} copyCount={item.count && item.count.toString()} />
            </Grid>
        ));
    };
    const status = (order) => {
        switch (order.status){
            case 'تحویل داده شد' :
                return (<MDBox ml={-1}>
                    <MDBadge badgeContent={order.status}
                             color='success'
                             variant="gradient" size="lg"/>
                </MDBox>)
            break
            case 'در راه' :
                return (<MDBox ml={-1}>
                    <MDBadge badgeContent={order.status}
                             color={order.status === "info"}

                             variant="gradient" size="lg"/>
                </MDBox>)
            break
            case 'در حال انجام' :
                return (<MDBox ml={-1}>
                    <MDBadge badgeContent={order.status}
                             color='info'
                             variant="gradient" size="lg"/>
                </MDBox>)
            break
            case 'منتظر پرداخت' :
                return (<MDBox ml={-1}>
                    <MDBadge badgeContent={order.status}
                             color='warning'

                             variant="gradient" size="lg"/>
                </MDBox>)
            break
            case 'لغو شد' :
                return (<MDBox ml={-1}>
                    <MDBadge badgeContent={order.status}
                             color='error'

                             variant="gradient" size="lg"/>
                </MDBox>)
            break
            case 'سفارش نا تمام' :
                return (<MDBox ml={-1}>
                    <MDBadge badgeContent={order.status}
                             color='warning'
                             variant="gradient" size="lg"/>
                </MDBox>)
            break
        }

    }
    const destroy = (order) => {
        return ( <MDButton
            component={Link}
            to={"/order/"+order.id}
            href={(e) => e.preventDefault()}
            target=""
            rel="noreferrer"
            size="small"
            variant="outlined"
            endIcon={<DeleteForever />}
            color="error"
        >
            حذف سفارش
        </MDButton>)
    }
    const action = (order) => {
        if (order.status == 'تحویل داده شد'){
            return ( <MDButton
                component={Link}
                to={"/order/"+order.id}
                href={(e) => e.preventDefault()}
                target=""
                rel="noreferrer"
                size="small"
                variant="outlined"
                endIcon={<AddCommentIcon />}
                color="success"
                // disabled
            >
                ثبت نظر
            </MDButton>)
        }else if(order.status == 'در راه'){
            return (<MDButton
                component={Link}
                to={"/order/"+order.id}
                href={(e) => e.preventDefault()}
                target=""
                rel="noreferrer"
                size="small"
                variant="outlined"
                endIcon={<MopedIcon fontSize="large" />}
                color="info"
            >
                پیگیری
            </MDButton>)
        }else if(order.status == 'لغو شد'){
            return (<MDButton
                disabled
                size="small"
                variant="outlined"
                endIcon={<CancelIcon fontSize="large" />}
                color="error"
            >
                لغو شده
            </MDButton>)
        }else if(order.status == 'در حال انجام'){
            return (<MDButton
                component={Link}
                to={"/order/"+order.id}
                href={(e) => e.preventDefault()}
                target=""
                rel="noreferrer"
                size="small"
                variant="outlined"
                endIcon={<MopedIcon fontSize="large" />}
                color="info"
            >
                پیگیری
            </MDButton>)
        }else if(order.status == 'منتظر پرداخت') {
            return (<MDButton
                component={Link}
                to={"/create-order/step3/"+order.id}
                href={(e) => e.preventDefault()}
                target=""
                rel="noreferrer"
                size="small"
                variant="outlined"
                endIcon={<MopedIcon fontSize="large" />}
                color="warning"
            >
                تکمیل سفارش
            </MDButton>)
        }else if(order.status == 'سفارش نا تمام') {
            return (<MDButton
                component={Link}
                to={"/create-order/step3/"+order.id}
                href={(e) => e.preventDefault()}
                target=""
                rel="noreferrer"
                size="small"
                variant="outlined"
                endIcon={<MopedIcon fontSize="large" />}
                color="warning"
            >
                تکمیل سفارش
            </MDButton>)
        }

    };

    const rows = data.map(order => ({
        function: (
            <Grid container spacing={2}>
                {orderItemHandler(order.order_items)}
            </Grid>
        ),
        status: (
            status(order)
        ),
        seller: (
            <MDTypography display="block" variant="caption" color="text" fontWeight="medium">
                {order.saler ? order.saler.name : 'دفتر فنی'}
            </MDTypography>
        ),
        price: (
            <MDBox >
                <MDTypography display="block" variant="caption" color="text" fontWeight="medium">
                    {' هزینه سفارش : '}        {numberFormat(order.price)} {' تومان '}
                </MDTypography>
                <MDTypography display="block" variant="caption" color="text" fontWeight="medium">
                    {' هزینه ارسال : '} {numberFormat(order.shippingPrice)}     {' تومان  '}
                </MDTypography>
            </MDBox>
        ),
        file: (
            <MDTypography target="_blank" component="a" href={order.order_items.length >0 ? order.order_items[0].file : ''} variant="caption" color="text" fontWeight="medium">
                    {(order.order_items.length >0 && order.order_items[0].file) ? ' دانلود فایل' : '-'}   
            </MDTypography>
        ),
        employed: (
            <MDTypography  component="a" href="#" variant="caption" color="text" fontWeight="medium">
                {order.creation_date}
            </MDTypography>
        ),
        action: (
            action(order)
        ),
    }));


    return {
        columns: [
            { Header: "نام دفتر فنی", accessor: "seller", align: "left" , width: "20%"},
            { Header: "جزییات سفارش", accessor: "function", align: "left" , width: "30%"},
            { Header: "وضعیت", accessor: "status", align: "center" , width: "5%"},
            { Header: "تاریخ", accessor: "employed", align: "center" , width: "5%"},
            { Header: "هزینه سفارش", accessor: "price", align: "center" , width: "5%"},
            { Header: "فایل", accessor: "file", align: "center" , width: "5%"},
            { Header: "پیگیری", accessor: "action", align: "center" , width: "45%"},
        ],

        rows: rows,
    };
}
