
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";


function Places({packages}) {
  return (
    <MDBox>
      <MDBox mb={1}>
        <MDTypography fontWeight="bold" sx={{
                  fontSize: {
                    xs: '0.9rem', // Small screens
                    sm: '1rem',   // Large screens
                    lg: '1.2rem',  // Extra-large screens
                  },
                  lineHeight: {
                    xs: 1.5,
                    sm: 1.6,
                    md: 1.7,
                    lg: 1.8,
                  },
                }}>
                  پکیج ها و لیست قیمت ها
        </MDTypography>
      </MDBox>
      <Grid container spacing={3}>
         {packages}
      </Grid>
    </MDBox>
  );
}

export default Places;
