// Data
import { Call, Drafts, Inbox } from "@mui/icons-material";
import { Card, CardActions, CardContent, CardMedia, Container, Grid, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Typography, useMediaQuery } from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import DefaultInfoCard from "examples/Cards/InfoCards/DefaultInfoCard";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import React, {useEffect, useState} from "react";
import { useLocation } from "react-router-dom";
import { staticImagesPrefix } from "services/helperFunctions";
import packageService from "services/package-service";

import {SupportTelegramLink} from "../../services/helperFunctions";

function CustomOrder() {
    const [category, setCategory] = useState(null);

    const useQuery = () => {
        return new URLSearchParams(useLocation().search);
      };

      const navItems = (categoryId) => {
        // Define the items for each category
        const items = {
          40: [
            "انواع خدمات چاپ در حوزه محصولات اداری ",
            "سربرگ",
            "فاکتور",
            "پاکت",
            "فولدر و ...",
          ],
          41: [

            "تراکت",
            "بروشور",
            "کاتالوگ",
            "انواع بنر های تبلیغاتی و ...",
          ],
          42: [

            "انواع جعبه",
            "باکس",
            "ساک دستی و ...",
          ],
          43: [
            "کارت ویزیت با قالب انتخابی شما",
            "کارت ویزیت با جنس انتخابی شما",
            "کارت ویزیت خاص و لوکس",
            "کارت ویزیت استاندارد (بهترین قیمت)",
            "انواع مختلف کارت های ویزیت شخصی سازه شده و ..."
          ],
          // Add more categories as needed
        };
      
        // Get the items based on categoryId
        const selectedItems = items[categoryId] || [];
      
        return (
          <nav>
            <ul>
              {selectedItems.map((item, index) => (
                <li key={index}>
                  <MDTypography variant="subtitle2">{item}</MDTypography>
                </li>
              ))}
            </ul>
          </nav>
        );
      };
      const fetchCategory = async (id) => {
        

        const response = await packageService.category(id);
        if(response.status == 'success'){
            setCategory(response.data)    
        }
     
    
    };

    const query = useQuery();
      useEffect(() => {
          fetchCategory (query.get('category'))
  
      }, []);

    return (
    <DashboardLayout>
        <DashboardNavbar/>
       
            <Grid container spacing={2} sx={{  gridTemplateRows: '1fr' }}>

            <Grid item xs={12} lg={6} >
                <Card >
                            <MDBox p={3}>
                            <Typography
                                        style={{ color: "#00a8ef", fontSize: '1.7rem', fontWeight: "bolder", marginRight: '8px' }}
                                    >
                                    {category && category.title}
                                </Typography>

                                <Grid container>
                                            <Grid item xs={12}>
                                                <MDBox p={3}>
                                                {navItems(category && category.id)}
                                                </MDBox>
                                            </Grid>
                                            {/* <Grid item xs={12}>
                                            <MDTypography variant="body2">
                                                با یک تماس قیمت بازار دستته !!!
                                            </MDTypography>
                                            </Grid> */}
                                </Grid>

                                <MDTypography variant="caption" 
                                style={{color : "#00a8ef" }}>
                                                برای اطلاع از قیمت ها و ثبت سفارش تماس بگیرید
                                </MDTypography>
                                <MDButton 
                                fullWidth
                                variant="contained" 
                                style={{ color : 'white', backgroundColor : "#00a8ef" }}
                                size="large"
                                href="tel:+989911661501"
                                endIcon={<Call />}
                            >
                            
                            09911661501 تماس بگیرید
                            </MDButton>
                            </MDBox>

                            </Card>
                    </Grid> 

                <Grid item xs={12} lg={6}>
                <Card>
                <CardMedia sx={{ height: 140, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <img
                        style={{
                        height: '100%', // Make the image take the full height of CardMedia
                        width: 'auto', // Keep aspect ratio
                        objectFit: 'cover', // Change to 'contain' if you want to avoid cropping
                        }}
                        src={`${staticImagesPrefix}/print.jpg`}
                        alt="print"
                    />
                </CardMedia>

                    <CardContent>
                        <Typography style={{ display: "flex", whiteSpace: "nowrap", alignItems: "center" }}>
                                    <Typography
                                        style={{ color: "#00a8ef", fontSize: '1.7rem', fontWeight: "bolder", marginRight: '8px' }}
                                    >
                                        ایزی چاپ&nbsp;
                                    </Typography>
                                    <span style={{ fontWeight: "normal", color: "inherit" , fontSize: '1.1rem' }}>همه قیمت هارو داره</span>
                        </Typography>
                        <Typography variant="caption" sx={{ color: 'text.secondary' }}>
                            ایزی چاپ همه جای ایران با بهترین ها  
                        </Typography>
                        <Typography variant="caption" sx={{ color: 'text.secondary' }}>
                          <br/>  ما کمترین قیمت ٬ بیشترین کیفیت و بالا ترین سرعت از میان چاپخانه ها و دفاتر فنی سراسر کشور را برای شما پیدا میکنیم  
                        </Typography>
                    </CardContent>
                    <CardActions>
                        <MDButton 
                                variant="contained" 
                                 size="small"
                                 target='_blank'
                                href={SupportTelegramLink}
                                style={{ color : 'white', backgroundColor : "#ffc010" }}
                                 >واحد پشتیبانی</MDButton>
                    </CardActions>
                </Card>
                
                </Grid>
            </Grid>  
      
    </DashboardLayout>
    )

}

export default CustomOrder;
