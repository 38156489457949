import HttpService from "./htttp.service";

class AuthService {
  // authEndpoint = process.env.API_URL;

  login = async (payload) => {

    const loginEndpoint = 'client/sign-in';
    return await HttpService.post(loginEndpoint, payload);
  };

  register = async (credentials) => {
    const registerEndpoint = 'client/register';
    return await HttpService.post(registerEndpoint, credentials);
  };

  logout = async () => {
    const logoutEndpoint = 'client/logout';
    return await HttpService.post(logoutEndpoint);
  };


  sendOtp = async (payload) => {
    const sendOtpEndpoint = 'client/send-otp';
    return await HttpService.post(sendOtpEndpoint, payload);
  };


  verifyOtp = async (payload) => {
    const verifyOtpEndpoint = 'client/verify-otp';
    return await HttpService.post(verifyOtpEndpoint, payload);
  };

  forgotPassword = async (payload) => {
    const forgotPassword = 'client/password-forgot';
    return await HttpService.post(forgotPassword, payload);
  }

  resetPassword = async (credentials) => {
    const resetPassword = 'client/password-reset';
    return await HttpService.post(resetPassword, credentials);
  }

  getProfile = async() => {
    const getProfile = 'me';
    return await HttpService.get(getProfile);
  }

  updateProfile = async (newInfo) => {
    const updateProfile = "me";
    return await HttpService.put(updateProfile, newInfo);
  }
}

export default new AuthService();
