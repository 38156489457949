import HttpService from "./htttp.service";

class OrderService {

  orders = async (payload) => {

    const ordersEndpoint = 'client/orders-show';
    return await HttpService.post(ordersEndpoint, payload);
  };

  order = async (payload, id) => {
    const orderEndpoint = 'client/orders/'+id;
    return await HttpService.get(orderEndpoint, payload);
  };
  clientAddresses = async (payload) => {
    const clientAddressesEndpoint = 'client-addresses';
    return await HttpService.get(clientAddressesEndpoint, payload);
  };

  assignAddressToOrder = async (payload, id) => {
    const assignAddressToOrderEndpoint = 'order/'+id+'/address';
    return await HttpService.post(assignAddressToOrderEndpoint, payload);
  };


  addOrderItem = async (data, id) => {
    console.log(data);
    
    const submitOrderEndpoint = '/order/'+id+'/order-item';
    return await HttpService.post(submitOrderEndpoint, data);
  };

    submitOrder = async (data, id) => {
    const submitOrderEndpoint = 'submit-order/' + id;
    return await HttpService.post(submitOrderEndpoint, data);
  };

}

export default new OrderService();
